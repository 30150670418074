// ----------------------------------------------------------------------
// Vertical align block
// ----------------------------------------------------------------------
// example: @include vertical-align();

@mixin vertical-align {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	margin: auto;
}

@mixin vertical-align-absolute {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	margin: auto;
}

@mixin vertical-align-flex {
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
}
