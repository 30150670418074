@import '../variables/shadow';

.box-shadow-xs {
	box-shadow: $box-shadow-xs;
}

.box-shadow-s {
	box-shadow: $box-shadow-s;
}

.box-shadow-m {
	box-shadow: $box-shadow-m;
}

.box-shadow-l {
	box-shadow: $box-shadow-l;
}
