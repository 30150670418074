@import '../variables';

.dx-datagrid-column-chooser,
.dx-datagrid-column-chooser input,
.dx-datagrid-column-chooser textarea,
.dx-menu-base,
.dx-menu-base input,
.dx-menu-base textarea,
.dx-overlay-wrapper,
.dx-overlay-wrapper input,
.dx-overlay-wrapper textarea,
.dx-field,
.dx-field input,
.dx-field textarea,
.dx-widget,
.dx-widget input,
.dx-widget textarea,
.dx-treelist-column-chooser input,
.dx-treelist-column-chooser textarea,
.dx-theme-generic-typography input,
.dx-theme-generic-typography textarea,
.dx-theme-generic-typography {
	font-family: $default-font;
	color: $color-black-10;
}

.dx-invalid.dx-texteditor.dx-editor-filled.dx-show-invalid-badge
	.dx-texteditor-input-container::after,
.dx-invalid.dx-texteditor.dx-editor-outlined.dx-show-invalid-badge
	.dx-texteditor-input-container::after,
.dx-invalid.dx-texteditor.dx-editor-underlined.dx-show-invalid-badge
	.dx-texteditor-input-container::after {
	content: '';
	background: url('/assets/images/error-icon.svg') transparent center center no-repeat content-box;
	background-size: pxToRem(20);
}
