@import '../base/mixins';

@-webkit-keyframes tada {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		-ms-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	10%,
	20% {
		-webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
		-ms-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
		transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
	}

	30%,
	50%,
	70%,
	90% {
		-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
		-ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}

	40%,
	60%,
	80% {
		-webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
		-ms-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		-ms-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-webkit-keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		-ms-transform: scale(0);
		transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}
}

@-webkit-keyframes zoomIn {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}

	50% {
		opacity: 1;
	}
}

@keyframes zoomIn {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}

	50% {
		opacity: 1;
	}
}

@keyframes zoomOut {
	from {
		opacity: 1;
	}

	50% {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}

	to {
		opacity: 0;
	}
}

@keyframes fadeInRight {
	from {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInLeft {
	from {
		opacity: 0;
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeOutRight {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeOutUp {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(42, 110, 240, 0.4);
	}
	10% {
		-webkit-box-shadow: 0 0 0 pxToRem(2) rgba(42, 110, 240, 0.3);
	}
	20% {
		-webkit-box-shadow: 0 0 0 pxToRem(4) rgba(42, 110, 240, 0.3);
	}
	70% {
		-webkit-box-shadow: 0 0 0 pxToRem(8) rgba(68, 140, 250, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(42, 110, 240, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(42, 110, 240, 0.4);
		box-shadow: 0 0 0 0 rgba(42, 110, 240, 0.4);
	}
	10% {
		-moz-box-shadow: 0 0 0 pxToRem(2) rgba(42, 110, 240, 0.3);
		box-shadow: 0 0 0 pxToRem(2) rgba(42, 110, 240, 0.3);
	}
	20% {
		-moz-box-shadow: 0 0 0 pxToRem(4) rgba(42, 110, 240, 0.3);
		box-shadow: 0 0 0 pxToRem(4) rgba(42, 110, 240, 0.3);
	}
	70% {
		-moz-box-shadow: 0 0 0 pxToRem(8) rgba(68, 140, 250, 0);
		box-shadow: 0 0 0 pxToRem(8) rgba(68, 140, 250, 0);
	}
	100% {
		-moz-box-shadow: 0 0 0 0 rgba(42, 110, 240, 0);
		box-shadow: 0 0 0 0 rgba(42, 110, 240, 0);
	}
}

.animated {
	@include animated($animation-duration);
}

.fadeOut {
	-webkit-animation-name: fadeOut !important;
	animation-name: fadeOut !important;
}

.tada {
	-webkit-animation-name: tada !important;
	animation-name: tada !important;
}

.zoomIn {
	-webkit-animation-name: zoomIn !important;
	animation-name: zoomIn !important;
}

.zoomOut {
	-webkit-animation-name: zoomOut !important;
	animation-name: zoomOut !important;
}

.fadeIn {
	-webkit-animation-name: fadeIn !important;
	animation-name: fadeIn !important;
}

.fadeInLeft {
	animation-name: fadeInLeft !important;
	-webkit-animation-name: fadeInLeft !important;
}

.fadeInRight {
	-webkit-animation-name: fadeInRight !important;
	animation-name: fadeInRight !important;
}

.fadeOutRight {
	-webkit-animation-name: fadeOutRight !important;
	animation-name: fadeOutRight !important;
}

.fadeInDown {
	-webkit-animation-name: fadeInDown !important;
	animation-name: fadeInDown !important;
}

.fadeOutUp {
	-webkit-animation-name: fadeOutUp !important;
	animation-name: fadeOutUp !important;
}

.transition-all {
	transition: all 0.3s ease-in-out !important;
}

@keyframes slideInUp {
	from {
		transform: translate3d(0, 100%, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

@mixin slideInUp {
	animation-name: slideInUp;
}

.slideInUp {
	@include slideInUp();
}
