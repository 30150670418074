.cleeksy-popup {
	.dx-overlay-content .dx-popup-content {
		padding: 0 !important;
	}

	.dx-overlay-content
		> .dx-popup-content
		> .dx-template-wrapper
		> .popup-container
		> .popup-content-header {
		@include rem(height, 60);
		@include rem(border-bottom, 1);
		border-style: solid;
		border-color: $color-black-2;
		background-color: #ffffff;

		.popup-title {
			height: 100%;
			align-items: center;
		}

		.title-text {
			@include rem(font-size, 16);
			@include rem(line-height, 28);
			font-weight: 600;
			color: $color-black-10;
		}
	}

	.dx-overlay-content
		> .dx-popup-content
		> .dx-template-wrapper
		> .popup-container
		> .popup-content-header
		> .popup-title
		> .title-actions
		> .button-close
		> .dx-icon-close {
		color: $color-black-10;
	}

	.dx-overlay-content
		> .dx-popup-content
		> .dx-template-wrapper
		> .popup-container
		> .popup-content-body {
		.popup-content-wrapper {
			padding: 0;
		}

		.dx-scrollable-content {
			padding: 0;
		}
	}

	.dx-overlay-content
		> .dx-popup-content
		> .dx-template-wrapper
		> .popup-container.fixed-footer
		> .popup-content-body {
		height: calc(100% - 132px) !important;
	}

	.dx-overlay-content
		> .dx-popup-content
		> .dx-template-wrapper
		> .popup-container.fixed-footer
		> .popup-content-footer {
		> .popup-footer-wrapper {
			@include rem(padding, 0);

			> div {
				@include rem(height, 69);
				@include rem(line-height, 69);
				@include rem(padding, 0, 24);
			}
		}
	}
}
