.side-panel-right {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 99;
	@include rem(width, 588);
	height: 100%;
	background: #fff;
	border-left: 1px solid $color-black-2;
	box-shadow: $box-shadow-m;
	pointer-events: auto;
	@include flexbox();
	@include flex-direction(column);
	@include flex(1, 1, 0);
}
