// 3rd packages
@import '@ctrl/ngx-emoji-mart/picker';
@import 'video.js/src/css/video-js';

// Mixins
@import 'variables';
@import 'base/mixins';
@import 'base/reset';

// component
@import 'components/scrollbar';
@import 'components/button';
@import 'components/popup';
@import 'components/popover';
@import 'components/grid';
@import 'components/list';
@import 'components/scroll';
@import 'components/switch';
@import 'components/text-area';
@import 'components/text-box';
@import 'components/tree';
@import 'components/select-box';
@import 'components/cicrle-process';
@import 'components/icon';
@import 'components/date-box';
@import 'components/date-range-picker';
@import 'components/box-item';
@import 'components/tooltip';
@import 'components/checkbox';
@import 'components/label';
@import 'components/tag-box';
@import 'components/product-grid';
@import 'components/radio-button';
@import 'components/popup-container';
@import 'components/dashboard-content';
@import 'components/order-delivery';
@import 'components/dx-dialog';
@import 'components/side-panel';
@import 'components/drag-drop';
@import 'components/link';
@import 'components/dx-toast';
@import 'components/number-box';
@import 'components/skeleton-loader';
@import 'components/quilljs';
@import 'components/drawflow';
@import 'components/gridstack';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'design-system';
@import 'legacy/style';

html,
body,
app-cleeksy {
	padding: 0;
	margin: 0;
	height: 100%;
	display: block;
}

body {
	font-family: $default-font;
	font-size: 0.875rem; // ~ 14px

	background-color: white;
	color: $color-black-10;
}

/*
	utility class to disable all content interaction.
	for example when the form is loading, saving...
*/
.disable-interact {
	cursor: default;
	pointer-events: none;
	opacity: 0.7;
}

.break-word {
	overflow-wrap: anywhere;
	word-break: normal;
}

/*Emoji styles*/

.emoji-mart-category .emoji-mart-emoji span {
	cursor: pointer;
}

/*Dx chart*/
dx-chart {
	.dxc-scroll-bar:hover,
	.dxc-scroll-bar:active {
		> rect {
			fill: #555;
		}
	}
}
