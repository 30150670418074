@import '../base/mixins';

.card {
	@include rem(padding, 8);
	@include rem(border-radius, 6);
	box-shadow: none;
	justify-content: space-between;
	flex-direction: initial;
	width: fit-content;
	cursor: pointer;
	transition: all 0.2s ease;

	&.state-checked {
		background: $color-blue-0-5;

		.name,
		.description,
		.item-icon {
			color: $color-blue-9;
		}

		&:hover {
			.name,
			.description,
			.item-icon {
				color: $color-black-10;
			}

			.description,
			.item-icon {
				color: $color-black-5;
			}
		}
	}

	&:hover {
		background: $color-black-1;
	}

	&.disable {
		pointer-events: none;
		background: $color-white;

		.name,
		.description,
		.item-icon {
			color: $color-black-5;
		}

		.text-added {
			@include rem(font-size, 12);
			@include rem(line-height, 20);
			white-space: pre;
			color: $color-black-5;
			font-weight: 400;
		}
	}

	&:focus,
	&:active {
		background: $color-black-2;
	}

	.icon {
		@include rem(line-height, 18);
	}

	.item-icon {
		@include rem(height, 16);
		@include rem(width, 27.5);
		@include rem(line-height, 18);
	}

	.name {
		@include rem(font-size, 14);
		@include rem(line-height, 20);
		color: $color-black-10;
		font-weight: 400;
	}

	.description {
		@include rem(font-size, 12);
		@include rem(line-height, 16);
		color: $color-black-5;
		font-weight: 400;
	}

	.link-text {
		@include rem(line-height, 16);
		@include rem(font-size, 12);
		font-weight: 500;
		color: $color-blue-7;
		text-decoration: none;
	}
}
