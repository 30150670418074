@import 'devextreme';
@import 'bootstrap';
@import 'box-shadow';
@import 'card';
@import 'animate';
@import 'zoom';

.cursor-pointer {
	cursor: pointer;
}

.font-weight-500 {
	font-weight: 500;
}

.red-color {
	color: red !important;
}

.green-color {
	color: green !important;
}

.purple-color {
	color: rebeccapurple !important;
}

.brown-color {
	color: saddlebrown !important;
}

.text-underline {
	text-decoration: underline;
}

.secondary-text-color {
	color: #6a7075;
}

.secondary-text-color:hover {
	color: #5e6367;
}

.popover-p-0 {
	.dx-popup-normal {
		padding: 0 !important;
	}

	.dx-popup-content {
		padding: 0 !important;
	}
}

.popover-p-10 {
	.dx-popup-content {
		padding: 10px !important;
	}
}

.popover-title {
	.dx-popup-normal {
		padding: 0 10px;
	}

	.dx-popup-content {
		padding: 10px 0;
	}

	.dx-toolbar {
		padding: 2px 0;
		border-radius: 5px 5px 0 0;

		.dx-toolbar-item {
			padding: 0 10px 0 0 !important;
		}
	}

	.dx-popup-title {
		background: #fff;
		color: #333 !important;
		font-weight: 600;
		padding: 6px 5px;
		min-height: 28px;
		border-bottom: 1px solid #ddd;
	}

	.dx-toolbar-before {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}

	.dx-toolbar-label {
		font-size: 13px !important;
	}

	.dx-icon-close {
		color: gray !important;
	}

	.dx-closebutton:hover {
		.dx-icon-close {
			background: #fff !important;
			color: #333 !important;
		}
	}
}

/**
 * List
 */

.list-item-p-0 {
	.dx-list-item {
		.dx-list-item-content {
			padding: 0;
		}
	}

	.dx-scrollable-wrapper {
		height: 100%;
	}
}

.name-avatar {
	color: #fff;
	cursor: pointer;
	height: 24px;
	width: 24px;
	@include rem(min-width, 24);
	@include rem(min-height, 24);
	border-radius: 50%;
	text-align: center;
	line-height: 24px;
	font-size: 12px;
	object-fit: cover;
	display: inline-block;
	overflow: hidden;
}

.extension {
	> i {
		font-family: 'Font Awesome 5 Free', sans-serif;
		font-weight: 400;
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		line-height: 1;

		&:before {
			content: '\f15b';
		}
	}

	&.doc,
	&.docx {
		color: #2b579a;

		> i:before {
			content: '\f1c2';
		}
	}

	&.xls,
	&.xlsx {
		color: #1f7244;

		> i:before {
			content: '\f1c3';
		}
	}

	&.ppt,
	&.pptx {
		color: #cc492e;

		> i:before {
			content: '\f1c4';
		}
	}

	&.jpg,
	&.jpeg,
	&.gif,
	&.png {
		color: #45b4fc;

		> i:before {
			content: '\f1c5';
		}
	}

	&.pdf {
		color: #e14045;

		> i:before {
			content: '\f1c1';
		}
	}

	&.zip {
		color: #fdcc77;

		> i:before {
			content: '\f1c6';
		}
	}

	&.rtf {
		color: #0385a9;

		> i:before {
			content: '\f15c';
		}
	}

	&.txt {
		color: #23505d;

		> i:before {
			content: '\f15c';
		}
	}

	&.sql {
		color: #fdcc77;

		> i:before {
			content: '\f15c';
		}
	}

	&.ogg,
	&.mp3,
	&.wav,
	&.m4a {
		color: #90c117;

		> i:before {
			content: '\f1c7';
		}
	}

	&.webm,
	&.mpeg4,
	&.mov,
	&.avi,
	&.mpeg,
	&.mpegps,
	&.wmv,
	&.flv,
	&.mp4 {
		color: #1a7273;

		> i:before {
			content: '\f1c8';
		}
	}
}

/*
* [Obsolete] old system code
* Should check to remove this
*/

// default dx-text-box, dx-tagbox, dx-autocomplete, dx-textarea of devextreme
.dx-texteditor.dx-editor-outlined,
.dx-htmleditor {
	background: #fff;
	border-color: $color-black-3;
	@include borderRadius(6px);

	&.dx-invalid {
		&.dx-state-focused {
			box-shadow: 0 0 0 3px $color-red-3;
		}
	}

	&.dx-state-hover {
		border-color: $color-black-5;
	}

	&.dx-state-focused {
		position: relative;
		z-index: 2;
		border-color: $color-blue-6;
		box-shadow: 0 0 0 3px $color-blue-3;
	}

	.dx-texteditor-container {
		@include borderRadius(8px);
	}
}

.dx-placeholder {
	color: $color-black-5;
}

.pointer {
	cursor: pointer;
}

.unselectable {
	@include unselectable();
}

// default field
.field {
	.field-label {
		color: $color-black-7;
		font-weight: 500;
		@include rem(margin-bottom, 8);
		@include rem(font-size, 12);

		&.required:after {
			content: ' *';
			color: red;
		}
	}
}

.absolute-wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

.img-responsive {
	margin: 0 auto;
	max-width: 100%;
	display: block;
}

.required:after {
	content: ' *';
	color: $color-red-8;
}

/**
 * Module Wrapper
 */
.md-wrapper {
	width: 100%;
	height: 100%;
	background: #f8f8f8;

	.menu-wrapper {
		width: 48px;
		height: 100%;
		line-height: 48px;
		border-right: 1px solid #ddd;
		font-size: 20px;
		clear: left;
		position: fixed;
		left: 0;
		background: white;

		.menu-item {
			display: block;
			padding: 0;
			width: 48px;
			height: 48px;
			text-align: center;
			color: #333;
			cursor: pointer;
			float: left;
			border-right: 1px solid transparent;
			border-bottom: 1px solid $color-black-3;

			a {
				color: inherit;
				display: inline-block;
				width: 100%;
				height: 100%;
			}

			&.active {
				color: $color-blue-7;
				border-right: 1px solid transparent;
			}
		}
	}

	> .content-wrapper {
		width: 100%;
		max-width: calc(100% - 48px);
		height: 100%;
		margin-left: 48px;
		padding: 0 12px;
		background: #f8f8f8;
		overflow-x: hidden;
		position: relative;
	}
}

/**
 * Form
 */
.form-wrapper {
	.tabs-wrapper {
		.tab-nav {
			border-bottom: 1px solid $color-black-3;

			.tab-item {
				display: inline-block;
				height: 100%;
				line-height: 22px;
				margin-right: 15px;
				cursor: pointer;

				&.active {
					color: $color-blue-7;
					border-bottom: 2px solid $color-blue-7;
				}
			}

			&.non-border-bottom {
				.tab-item {
					border-bottom: none;
				}
			}
		}
	}

	.card {
		width: 100%;
		height: 100%;
	}

	.form-group {
		padding: 10px;
		border: 1px solid #ccc;
		margin-bottom: 20px;
		border-radius: 0.15rem;
		@include shadow($box-shadow-m);

		.group-title {
			font-weight: bold;
			margin-bottom: 10px;
			border-bottom: 2px solid $color-blue-7;
			padding-bottom: 3px;
			display: inline-block;
		}
	}

	.field-group {
		margin-top: -0.5rem;
	}

	> .field:first-of-type {
		margin-top: 0;
	}

	.field {
		margin-top: 0.4rem;
		@include flexbox();
		@include flex-direction(column);

		&.row-layout {
			@include flex-direction(row);
			@include align-items(center);

			.field-label {
				margin-right: 1rem;
				min-width: 10%;
			}
		}

		.field-label {
			text-align: left;
			margin-bottom: 4px;

			&.required:after {
				content: ' *';
				color: red;
			}
		}

		.field-value {
		}

		.field-label-bold {
			font-weight: 500;
		}
	}
}

.dx-field {
	.dx-field-label {
		&.required:after {
			content: ' *';
			color: red;
		}
	}
}

.quick-add-input-wrapper {
	position: relative;

	.dx-texteditor.dx-invalid .dx-texteditor-input-container:after,
	.dx-texteditor.dx-invalid.dx-editor-filled .dx-texteditor-input-container:after,
	.dx-texteditor.dx-invalid.dx-editor-underlined .dx-texteditor-input-container:after,
	.dx-texteditor.dx-invalid.dx-editor-outlined .dx-texteditor-input-container:after {
		right: 24px;
	}

	.dx-texteditor.dx-dropdowneditor-button-visible .dx-texteditor-input {
		padding-right: 58px;
	}

	.add-btn {
		position: absolute;
		top: 0;
		cursor: pointer;
		@include rem(right, 36);

		@include rem(line-height, 36);
		@include rem(font-size, 18);
		z-index: 3;

		&.active,
		&:hover {
			i {
				color: $color-blue-7;
			}
		}
	}
}

/**
 * Tabs
 */
.tab-wrapper {
	height: 49px;
	line-height: 46px;
	border-bottom: 1px solid $color-black-3;

	.tab-item {
		display: inline-block;
		height: 100%;
		padding: 0 9px;

		a {
			display: inline-block;
			text-decoration: none;
			color: inherit;
		}

		&.active {
			color: $color-blue-7;
			border-bottom: 2px solid $color-blue-7;
		}
	}
}

.dx-icon-attach {
	mask-position: 50%;
	mask-repeat: no-repeat;
	-webkit-mask-size: contain;
	mask-size: contain;
	mask-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAAAAXNSR0IArs4c6QAABNRJREFUWEetmFXIblUQhp8DYotiF6iYoIJHRY/dHccOjoEiHLxTbC+8VAz0xgtR4Ygtdne3YBeKGBc2diIo8vzOknHc+/v2H3P3rTVrzbsn3/XNYmZkW2AX4FLgm5m58p9bZs3AZdsDDwKLAtcD8wbcuRawEvAx8MUo/ekC3AF4IMBp52bgsB6Dc4FjgN2BJZOOAG8P779Tz04H4E7AvcBicenbgGtfFyPrA9cBmw3wrBE4Efix6U4V4M7APQncW4BrFdyewP0dwJ4B/gQWBrYq+58A2wCfTjUHLQbBmXPKG+G5b4uhLnCXABcBnyXdtYEzgRPSmtHYaCoAdwMeShe9HuC+K+D2iNxsy78A+wBPjgjzwcAtaf94YMFkQrwXcF+64LUIawVX9QTnhz0/IAfPC2+q6v2zhwKsRl+NvlfB1bAKblfghQHgVFk8wr906K8+BOC+wN3JgOAsiO+LUUNobjb5KcC9NBBcUzPMhluZOw7g/sCdycArAe6HMeDct5heniQ41XOY548CaGO9IxnQmEaHgNPDfkwVK/9KYCngKEAvV7HST4rFeX0ADwRuSyfNISdAvbCG1bCbc12eE5yp4r5yOnBhB0BtbRnrc7oAHgTcmg6+GFVYwdXcFJyeM0erOG3MT/ebOHWeKIo27edi7Wc9XQGKPFecyvY0lbPU3BS8c3kouKOBa8udVrCe3yDWL3PsZYDLA28CK4fCs4BtYxw4W82OMVGq5zQq05GONZHtOHOzGH7nevOws3gdR2cGKBM5JE59CGzaUxAWzkKhJ7jtAEdTV1gfAbaODWfvocFcsu4iMXX8SOWPKEbn9b980C98Op3aEKjUp4bVnPNSx10XOD0n+CbSMJ1QxY+wOzQxt/XmhDQPyscOiLXLgfnlltpyJAbmnCymilxPo60S3TcyufBc6yoc7dyVLxTgEiXP1gU+SEqzS0/Tc7Jo87ULnJ5rYXXfD8/Nvp15tFT1/8A1D5qYKit6ZONi9WrAqlPke3ru3Q5wNl89t0Xa6zKq5wyhbaZJ30dMhNiOfk1oLgCkOVmsYr2sbN7ThB3u0rAM7j+5FOcnBa558JQgkf4+P9Edf68JfBSXfxUPneo8wT0WVd/29u5g0l3gHArmf6/owZOBi0PjAuCMpG1vzDR+WSBTrGUiPWxJyu+A1f5wsdhVEGPBNQ8emRrnVcBx5XKnwyaxZm/aL6jWCtGELSLlt9hr+dyu6QInncqzfqQH5yS2+z7gKyzLEcANaUGP2iPNx5abvwLm3OMDPNfVckYCdNN/AwyfYpU+VU6cC5zVc4tFpFfr4Fe9thInSX539AJrG61RX5FeVVajBKGKk0CK1N635ps97+yOqdNVEH2TZCTIBnA94L2k2duXAKvWp2IXIW1XTNtz1YP+tkCOjQ3DZk/rasijvticlJTmJmwO3zQ2lj0Kmc0sB0hO9Y4itZdZ18Tvs7VqhHziwR3/HAhuUjlXL6+EdY2o6FWSou3Ad8IE/ekQz5wa7alVtWq2rxun6rmuELc1+5u0yGrO4py26/+VFu2PztssX8azUcI7bRn1qnOinBOP6aGGJBanAY7FGZFx7+IVY/QdDqzWY9F/D6RTjsupvINHfsg4gPmw89Z3gsUgTf8c8K+yUX8ITduLfwP/CgmUOZ8JAgAAAABJRU5ErkJggg==');
	-webkit-mask-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAAAAXNSR0IArs4c6QAABNRJREFUWEetmFXIblUQhp8DYotiF6iYoIJHRY/dHccOjoEiHLxTbC+8VAz0xgtR4Ygtdne3YBeKGBc2diIo8vzOknHc+/v2H3P3rTVrzbsn3/XNYmZkW2AX4FLgm5m58p9bZs3AZdsDDwKLAtcD8wbcuRawEvAx8MUo/ekC3AF4IMBp52bgsB6Dc4FjgN2BJZOOAG8P779Tz04H4E7AvcBicenbgGtfFyPrA9cBmw3wrBE4Efix6U4V4M7APQncW4BrFdyewP0dwJ4B/gQWBrYq+58A2wCfTjUHLQbBmXPKG+G5b4uhLnCXABcBnyXdtYEzgRPSmtHYaCoAdwMeShe9HuC+K+D2iNxsy78A+wBPjgjzwcAtaf94YMFkQrwXcF+64LUIawVX9QTnhz0/IAfPC2+q6v2zhwKsRl+NvlfB1bAKblfghQHgVFk8wr906K8+BOC+wN3JgOAsiO+LUUNobjb5KcC9NBBcUzPMhluZOw7g/sCdycArAe6HMeDct5heniQ41XOY548CaGO9IxnQmEaHgNPDfkwVK/9KYCngKEAvV7HST4rFeX0ADwRuSyfNISdAvbCG1bCbc12eE5yp4r5yOnBhB0BtbRnrc7oAHgTcmg6+GFVYwdXcFJyeM0erOG3MT/ebOHWeKIo27edi7Wc9XQGKPFecyvY0lbPU3BS8c3kouKOBa8udVrCe3yDWL3PsZYDLA28CK4fCs4BtYxw4W82OMVGq5zQq05GONZHtOHOzGH7nevOws3gdR2cGKBM5JE59CGzaUxAWzkKhJ7jtAEdTV1gfAbaODWfvocFcsu4iMXX8SOWPKEbn9b980C98Op3aEKjUp4bVnPNSx10XOD0n+CbSMJ1QxY+wOzQxt/XmhDQPyscOiLXLgfnlltpyJAbmnCymilxPo60S3TcyufBc6yoc7dyVLxTgEiXP1gU+SEqzS0/Tc7Jo87ULnJ5rYXXfD8/Nvp15tFT1/8A1D5qYKit6ZONi9WrAqlPke3ru3Q5wNl89t0Xa6zKq5wyhbaZJ30dMhNiOfk1oLgCkOVmsYr2sbN7ThB3u0rAM7j+5FOcnBa558JQgkf4+P9Edf68JfBSXfxUPneo8wT0WVd/29u5g0l3gHArmf6/owZOBi0PjAuCMpG1vzDR+WSBTrGUiPWxJyu+A1f5wsdhVEGPBNQ8emRrnVcBx5XKnwyaxZm/aL6jWCtGELSLlt9hr+dyu6QInncqzfqQH5yS2+z7gKyzLEcANaUGP2iPNx5abvwLm3OMDPNfVckYCdNN/AwyfYpU+VU6cC5zVc4tFpFfr4Fe9thInSX539AJrG61RX5FeVVajBKGKk0CK1N635ps97+yOqdNVEH2TZCTIBnA94L2k2duXAKvWp2IXIW1XTNtz1YP+tkCOjQ3DZk/rasijvticlJTmJmwO3zQ2lj0Kmc0sB0hO9Y4itZdZ18Tvs7VqhHziwR3/HAhuUjlXL6+EdY2o6FWSou3Ad8IE/ekQz5wa7alVtWq2rxun6rmuELc1+5u0yGrO4py26/+VFu2PztssX8azUcI7bRn1qnOinBOP6aGGJBanAY7FGZFx7+IVY/QdDqzWY9F/D6RTjsupvINHfsg4gPmw89Z3gsUgTf8c8K+yUX8ITduLfwP/CgmUOZ8JAgAAAABJRU5ErkJggg==');
	background-color: $color-black-7;
}

.amount-overview {
	margin-right: -5px;
	margin-left: -5px;
	margin-bottom: 5px;
	@include unselectable();

	.item {
		padding: 0 5px;
		margin-bottom: 5px;

		&.selected {
			.card {
				border-bottom-color: initial;
				@include shadow($box-shadow-m);
			}

			.clear-filter {
				display: block;
			}
		}

		.clear-filter {
			position: absolute;
			top: 3px;
			right: 5px;
			font-size: 0.75rem;
			color: inherit;
			display: none;

			&:hover {
				color: #ccc;
			}
		}

		.card {
			cursor: pointer;
			box-shadow: none;
			background-color: $color-black-2;
			border-bottom: 3px solid transparent;

			.card-body {
				padding: 7px 0 7px 10px;
			}

			&.yellow {
				color: #c69500;
			}

			&.blue {
				color: $color-blue-7;
			}

			&.light-blue {
				color: $color-blue-4;
			}

			&.red {
				color: #f86c6b;
			}

			&.green {
				color: #149c6a;
			}

			&.orange {
				color: #ff7d00;
			}

			&.purple {
				color: #9b0092;
			}

			.amount-value {
				font-weight: bold;
				font-size: 1rem;
			}
		}
	}
}

.cleeksy-form-wrapper {
	.field {
		@include rem(margin, 0, 0, 16, 0);

		.field-label {
			font-weight: 700;
			@include rem(font-size, 12);
			@include rem(line-height, 16);
			@include rem(margin-bottom, 4);
			color: $color-black-5;

			.asterisk {
				color: red;
			}
		}

		.field-value {
			// text right of currency field.
			dx-number-box {
				&.amount-currency {
					.dx-texteditor-input {
						text-align: right;
					}
				}
			}

			// date box of cleeksy
			&.cleeksy-date-box {
				position: relative;

				.dx-button-normal {
					width: 28px;
					min-width: 28px;
				}

				.dx-button-content {
					display: none;
				}

				&:hover {
					dx-date-box {
						background-color: $color-black-1;
					}
				}

				.dx-datebox-calendar .dx-dropdowneditor-icon {
					&:before {
						content: '';
					}
				}

				.item-icon.icon_calendar {
					width: 16px;
					height: 16px;
					top: 7px;
					right: 8px;
					float: right;
					cursor: pointer;
					position: absolute;
					z-index: 1;
				}
			}
		}
	}

	.add-item-message {
		@include rem(margin-top, 8);
		font-weight: 400;
		@include rem(font-size, 12);
		@include rem(line-height, 16);
		color: $color-black-5;
	}
}

.cleeksy-view-form-wrapper {
	.field {
		@include rem(padding, 16);

		.field-label {
			@include rem(padding, 0, 8);
			@include rem(margin-bottom, 12);
			font-weight: 600;
			@include rem(font-size, 14);
			@include rem(line-height, 20);
			color: $color-black-10;

			.asterisk {
				color: red;
			}
		}

		.field-value {
			.item-add-new {
				@include rem(height, 36);
				@include rem(padding, 6, 8);
				@include rem(border-radius, 6);
				width: fit-content;

				&:hover {
					background: $color-black-1;
				}

				.icon_user_circle_dash {
					@include rem(width, 24);
					@include rem(height, 24);
				}

				.text-add-new {
					@include rem(line-height, 24);
					color: $color-black-7;
				}
			}
		}

		.info-user {
			@include rem(padding, 0, 8);

			&-avatar {
				.item-icon > svg {
					border-radius: 50%;
				}

				.user-icon {
					@include rem(width, 32);
					@include rem(height, 32);
					@include rem(line-height, 32);
				}

				> img {
					@include rem(width, 32);
					@include rem(height, 32);
					border-radius: 50%;
					object-fit: cover;
					@include rem(margin-top, 6);
				}
			}

			&-group-action {
				.icon-edit-contact,
				.icon-edit-company,
				.icon-delete {
					visibility: visible !important;
					display: block;
					@include rem(height, 32);
					@include rem(width, 32);
					@include rem(padding, 8);
					@include rem(border-radius, 6);
					line-height: 0;

					&:hover {
						background: $color-black-1;
					}

					.item-icon {
						visibility: visible !important;
						display: block;
					}
				}

				.icon-edit-contact,
				.icon-edit-company {
					@include rem(border-width, 1);
					border-style: solid;
					border-color: $color-black-2;
				}
			}

			&-fullname-address {
				.name {
					font-weight: 400;
				}

				.number-phone,
				.address {
					font-weight: 400;
					color: $color-black-7;
				}
			}
		}
	}

	.line {
		border-top: 1px solid $color-black-2;
		@include rem(margin, 0, 24);
	}
}
