$animationTime: 300;

.dx-popup-wrapper {
	> .dx-overlay-content {
		&.dx-popup-fullscreen {
			border-radius: 0;
		}

		margin: 0 !important;
		@include rem(min-width, 100);
		@include borderRadius(12px);
	}

	&.full-wrapper {
		width: 100% !important;
		height: 100% !important;
	}

	&.content-no-padding {
		.dx-popup-content {
			@include rem(padding, 0);
		}
	}
}

.dx-popup-title {
	color: #fff;
	background-color: $color-blue-5;
	border-bottom: 1px solid $color-black-2;

	.dx-item {
		max-width: 100% !important;
	}

	.dx-icon.dx-icon-close {
		color: #fff;
	}
}

.dx-popup-bottom {
	border-top: 1px solid $color-black-3;
	background-color: white;
}

.dx-popup-title.dx-toolbar .dx-toolbar-items-container {
	height: 24px;
}

.dx-popup-title.dx-toolbar .dx-toolbar-label {
	font-size: 16px;
}

.title-popover .dx-closebutton {
	background: transparent;
	border: 0;
}

// style background popup
.dx-overlay-shader {
	background-color: rgba(22, 25, 35, 0.4);
}
