/**
 * Tree List
 */
dx-tree-list {
	.action-inline {
		color: $color-blue-7;

		i {
			cursor: pointer;
			display: inline-block;
			margin: 0 5px;
			font-size: 16px;
		}
	}

	.dx-treelist-rowsview {
		.dx-treelist-empty-space {
			width: 20px;
		}

		.dx-treelist-expanded,
		.dx-treelist-collapsed {
			span {
				font-size: 25px;
			}
		}
	}

	&.row-hover-cursor-pointer {
		.dx-row.dx-data-row.dx-state-hover {
			cursor: pointer;
		}
	}

	.dx-treelist-table {
		.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(
				.dx-row-removed
			):not(.dx-edit-row):not(.dx-row-focused)
			> td:not(.dx-focused) {
			background-color: $color-black-1;
		}
	}

	.dx-edit-row.dx-treelist-edit-form {
		.dx-form {
			@media (max-width: 576px) {
				.dx-treelist-edit-form-item {
					@include flex-direction(column);
				}
			}
		}

		.dx-treelist-form-buttons-container {
			.dx-button:first-of-type {
				float: right;
				background-color: $color-blue-7;
				border-color: transparent;
				color: #fff;

				&.dx-state-hover {
					background-color: $color-blue-5;
					border-color: transparent;
				}
			}
		}
	}

	.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
		padding: 10px;
	}

	.dx-master-detail-row.dx-adaptive-detail-row {
		.dx-master-detail-cell {
			padding: 10px;

			.dx-form {
				.dx-box-item:not(:first-child) .dx-single-column-item-content > .dx-field-item {
					padding-top: 0;
				}

				.dx-box-item .dx-single-column-item-content > .dx-field-item {
					.dx-field-item-label {
						margin-bottom: 0;
					}

					.dx-field-item-content {
						padding-top: 3px;
						padding-bottom: 3px;
						white-space: normal;
					}
				}
			}
		}
	}
}
