// ----------------------------------------------------------------------

// Box Model

// ----------------------------------------------------------------------

@mixin box-sizing($box-model) {
	-webkit-box-sizing: $box-model;
	-moz-box-sizing: $box-model;
	box-sizing: $box-model;
}
