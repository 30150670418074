@import '../variables';
@import '../base/mixins';

.dx-dialog-wrapper.dx-dialog-root {
	@include rem(border-radius, 12);
}

.dx-dialog-wrapper.dx-dialog-root > .dx-overlay-content {
	@include rem(min-width, 320);
	@include rem(max-width, 488);

	.dx-popup-title {
		@include rem(padding, 20);
		border: none;
		background: white;
		color: $color-black-10;

		@include rem(font-size, 16);
		font-weight: 600;
	}

	.dx-popup-content {
		@include rem(padding-left, 20);
		@include rem(padding-right, 20);
		padding-top: 0;
		padding-bottom: 0;
	}

	.dx-popup-bottom {
		border: none;
	}

	.dx-popup-bottom .dx-toolbar-center {
		margin: 0;
		width: 100%;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		justify-content: space-between;
		@include rem(gap, 12);

		> .dx-item.dx-toolbar-button {
			flex-grow: 1;
			padding: 0;
		}

		> .dx-toolbar-item.dx-toolbar-button > .dx-item-content {
			@include rem(min-width, 70);
		}

		> .dx-toolbar-item.dx-toolbar-button > .dx-item-content > .dx-button {
			width: 100%;
			@include rem(min-width, 70);
		}

		/*Style for alert ok button*/
		> .dx-item.dx-toolbar-button .dx-button[aria-label='OK'] {
			background: $color-blue-7;
			border: 1px solid transparent;
			color: white;

			&:hover {
				background: $color-blue-4;
			}

			&:active,
			&.active {
				background: $color-blue-8;
			}
		}
	}
}
