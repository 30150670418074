// example: @include text-trunk(1) --> lorem any work...

@mixin text-truncate($line) {
	display: -webkit-box;
	-webkit-line-clamp: $line;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-word;
	white-space: normal;
}
