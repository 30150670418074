// ----------------------------------------------------------------------

// Animations

// ----------------------------------------------------------------------

// example: @include animation('slide-down 5s 3');

@mixin animation($str) {
	-webkit-animation: #{$str};
	-moz-animation: #{$str};
	-ms-animation: #{$str};
	-o-animation: #{$str};
	animation: #{$str};
}

$animation-duration: 0.3s;
@mixin animated($duration) {
	// https://blog.teamtreehouse.com/increase-your-sites-performance-with-hardware-accelerated-css
	// Request GPU process animation to increase performance
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);

	// Avoid flickering effect on chrome and safari
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;

	-webkit-perspective: 1000px;
	-moz-perspective: 1000px;
	perspective: 1000px;

	-webkit-animation-duration: $duration;
	animation-duration: $duration;

	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
