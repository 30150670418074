// override devextreme
.dx-sortable-dragging > * {
	box-shadow: $box-shadow-m;
}

.dx-sortable-placeholder:not(.dx-sortable-placeholder-inside) {
	border-width: 0 0 pxToRem(1) 0;
	border-color: $color-blue-5;
	&:before {
		content: '';
		@include vertical-align-absolute();
		left: 0;
		border-style: solid;
		border-width: pxToRem(3) 0 pxToRem(3) pxToRem(6);
		border-color: transparent transparent transparent $color-blue-5;
		display: inline-block;
		vertical-align: middle;
	}
}

.dx-sortable-placeholder-inside {
	border-width: pxToRem(1);
	border-color: $color-blue-5;
}
