.order-delivery-wrapper {
	width: 100%;
	height: 100%;
	.form-heading {
		font-style: normal;
		font-weight: 600;
		@include rem(font-size, 14);
		@include rem(line-height, 20);
		color: $color-black-10;
	}
	.form-text {
		font-style: normal;
		font-weight: 400;
		@include rem(font-size, 12);
		@include rem(line-height, 16);
		color: $color-black-5;
		@include rem(margin-bottom, 4);
	}
	.form-buttons {
		@include flex-shrink(0);
		@include flexbox();
		@include align-items(center);
		dx-button {
			@include rem(width, 32);
			@include rem(height, 32);
			@include center-align-flex();
		}
	}
	.section-divider {
		width: 100%;
		@include rem(height, 12);
		background-color: $color-section-gray;
	}

	.field {
		@include rem(padding, 24, 16);
		border-bottom: 1px solid $color-black-2;
		@include rem(border-width, 1);
	}
	.default-badge {
		@extend .form-text;
		display: inline-block;
		font-weight: 600;
		color: $color-blue-6;
	}
}
