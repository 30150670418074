@import '../base/mixins';
@import '../variables/color';
@import '../variables/container-size';

/*Icon size*/
$extra-small-icon-size: 16;
$small-icon-size: 16;
$medium-icon-size: 20;
$large-icon-size: 24;

.cui-button {
	@include flexbox();
	@include flex-direction(row);

	@include align-items(center);
	@include justify-content(center);
	@include rem(gap, 8);
	cursor: pointer;
	@include rem(font-size, 14);

	// Padding lef,right 12px if button have no icon
	&:not(.cui-button--only-icon) {
		@include rem(padding, 0, 12);
	}

	// Reverse row to show icon in the right
	&--icon-in-right {
		@include flex-direction(row-reverse);
	}

	@include rem(border-radius, 6);
	border: none;

	&--circle {
		border-radius: 50%;
	}

	&--disabled {
		pointer-events: none;
	}

	// Icon
	.cui-button__icon {
		color: inherit;
		@include inline-flex();
		@include justify-content(center);
		@include align-items(center);
		// Default icon size
		@include rem(width, $medium-icon-size);
		@include rem(height, $medium-icon-size);
	}

	// Text
	.cui-button__text {
		font-weight: 500;

		@include text-truncate(1);
		user-select: none;
	}

	/* =========Sizing========*/
	// Default height, default with if icon button
	@include rem(height, $h-m);
	@include rem(line-height, $h-m);

	&.cui-button--only-icon {
		@include rem(width, $h-m);
	}

	// End default size

	&--extra-small {
		@include rem(height, $h-xs);
		@include rem(line-height, $h-xs);

		@include rem(font-size, 12);

		&.cui-button--only-icon {
			@include rem(width, $h-xs);
		}

		font-weight: 500;

		.cui-button__icon {
			@include rem(width, $extra-small-icon-size);
			@include rem(height, $extra-small-icon-size);
		}
	}

	&--small {
		@include rem(height, $h-s);
		@include rem(line-height, $h-s);

		@include rem(font-size, 12);
		font-weight: 500;

		&.cui-button--only-icon {
			@include rem(width, $h-s);
			@include rem(font-size, 12);
		}

		.cui-button__icon {
			@include rem(width, $small-icon-size);
			@include rem(height, $small-icon-size);
		}
	}

	&--medium {
		@include rem(height, $h-m);
		@include rem(line-height, $h-m);

		&.cui-button--only-icon {
			@include rem(width, $h-m);
		}

		@include rem(font-size, 14);
		font-weight: 500;

		.cui-button__icon {
			@include rem(width, $medium-icon-size);
			@include rem(height, $medium-icon-size);
		}
	}

	&--large {
		@include rem(height, $h-l);
		@include rem(line-height, $h-l);

		&.cui-button--only-icon {
			@include rem(width, $h-l);
		}

		@include rem(font-size, 16);
		font-weight: 500;

		.cui-button__icon {
			@include rem(width, $large-icon-size);
			@include rem(height, $large-icon-size);
		}
	}

	/*Type*/
	&--primary {
		background: $color-blue-7;
		color: white;

		&:hover {
			background: $color-blue-4;
		}

		&:active,
		&.active {
			background: $color-blue-8;
		}

		&.cui-button--disabled {
			background: $color-black-1;
			color: $color-black-5;
		}
	}

	&--secondary {
		background: $color-blue-1;
		color: $color-blue-7;

		&:hover {
			background: $color-blue-2;
			color: $color-blue-9;
		}

		&:active,
		&.active {
			background: $color-blue-3;
			color: $color-blue-9;
		}

		&.cui-button--disabled {
			background: $color-black-1;
			color: $color-black-5;
		}
	}

	&--indicative {
		background: white;
		color: $color-black-7;
		@include border(border, 1, solid, $color-black-3);

		&:hover {
			&.cui-button--color-blue {
				background: $color-blue-0-5;
				color: $color-blue-6;
				border-color: $color-blue-6;
			}

			&.cui-button--color-green {
				background: $color-green-0-5;
				color: $color-green-6;
				border-color: $color-green-6;
			}

			&.cui-button--color-yellow {
				background: $color-yellow-0-5;
				color: $color-yellow-6;
				border-color: $color-yellow-6;
			}

			&.cui-button--color-red {
				background: $color-red-0-5;
				color: $color-red-6;
				border-color: $color-red-6;
			}
		}

		&:active,
		&.active {
			&.cui-button--color-blue {
				background: $color-blue-2;
				color: $color-blue-6;
				border-color: $color-blue-6;
			}

			&.cui-button--color-green {
				background: $color-green-2;
				color: $color-green-6;
				border-color: $color-green-6;
			}

			&.cui-button--color-yellow {
				background: $color-yellow-2;
				color: $color-yellow-6;
				border-color: $color-yellow-6;
			}

			&.cui-button--color-red {
				background: $color-red-2;
				color: $color-red-6;
				border-color: $color-red-6;
			}
		}

		&.cui-button--disabled {
			border-color: $color-black-1;
			background: $color-black-1;
			color: $color-black-5;
		}
	}

	&--tertiary {
		background: white;
		color: $color-black-7;
		@include border(border, 1, solid, $color-black-3);

		&:hover {
			@include border(border, 1, solid, $color-black-3);
			background: $color-black-1;
			color: $color-black-10;
		}

		&:active,
		&.active {
			border-color: $color-black-2;
			background: $color-black-2;
			color: $color-black-10;
		}

		&.cui-button--disabled {
			border-color: $color-black-1;
			background: $color-black-1;
			color: $color-black-5;
		}
	}

	&--transparent {
		background: transparent;
		color: $color-black-7;

		&:hover {
			background: $color-black-1;
			color: $color-black-10;
		}

		&:active,
		&.active {
			background: $color-black-2;
			color: $color-black-10;
		}

		&.cui-button--disabled {
			background: $color-black-1;
			color: $color-black-5;
		}
	}

	&--destructive {
		background: $color-red-5;
		color: white;

		&:hover {
			background: $color-red-6;
			color: white;
		}

		&:active,
		&.active {
			background: $color-red-8;
			color: white;
		}

		&.cui-button--disabled {
			color: $color-black-5;
			background: $color-black-1;
		}
	}

	&--link {
		color: $color-blue-7;
		background: transparent;
		border: none;

		.cui-button__text {
			border-bottom: #{pxToRem(1)} solid transparent;
		}

		&:hover {
			color: $color-blue-6;

			.cui-button__text {
				border-bottom: #{pxToRem(1)} solid $color-blue-6;
			}
		}

		&:active,
		&.active {
			color: $color-blue-8;

			.cui-button__text {
				border-bottom: #{pxToRem(1)} solid $color-blue-8;
			}
		}

		&.cui-button--disabled {
			color: $color-black-5;
		}
	}
}
