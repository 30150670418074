@import '../variables';

dx-list {
	.dx-list-search.dx-texteditor {
		@include rem(height, 34);
		@include rem(border-radius, 6);
	}
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active,
.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused {
	background-color: $color-black-1;
	color: $color-black-10;
}
