/**
 * Text Box
 */
// default text box _global.scss with class dx-texteditor.dx-editor-outlined
dx-text-box.dx-textbox.dx-texteditor {
	&.dx-state-readonly {
		background: $color-black-0-5;
		border-style: solid;
		border-color: $color-black-2;
		box-shadow: none;
		cursor: none;

		.dx-texteditor-input {
			font-weight: 400;
			@include rem(font-size, 14);
			@include rem(line-height, 20);
			color: $color-black-7;
		}
	}

	&.dx-editor-outlined.dx-state-focused .dx-placeholder,
	&.dx-htmleditor.dx-state-focused .dx-placeholder {
		display: block;
	}

	&.dx-state-disabled .dx-widget,
	&.dx-state-disabled.dx-widget {
		background-color: #fff;
	}
}

/*
	Examplate:
	<dx-text-box class="input-small" placeholder="Type last name..."></dx-text-box>
	<dx-select-box class="input-small" searchEnabled="true" [items]="simpleProducts"></dx-select-box>
*/
.input-small {
	.dx-texteditor-input {
		@include rem(min-height, 30);
		@include rem(padding, 5, 7, 6);
	}

	.dx-placeholder {
		@include rem(font-size, 12);

		&:before {
			@include rem(padding, 6, 7, 6);
		}
	}
}

/*
	Examplate:
	<dx-text-box class="input-large" placeholder="Type last name..."></dx-text-box>
	<dx-select-box class="input-large" searchEnabled="true" [items]="simpleProducts"></dx-select-box>
*/
.input-large {
	@include rem(font-size, 16);

	.dx-texteditor-input {
		@include rem(min-height, 42);
		@include rem(padding, 9, 12, 10);
	}

	.dx-placeholder {
		&:before {
			@include rem(padding, 9, 12, 10);
		}
	}
}

/*
	Example:
	<div class="txt-search">
		<app-svg-icon
					class="search-icon"
					[name]="'icon_search'">
		</app-svg-icon>
		<dx-text-box class="border-0 text-box-no-background"
					[placeholder]="'Tìm kiếm'">
		</dx-text-box>
	</div>
*/

.txt-search {
	position: relative;
	@include rem(min-width, 204);

	.search-icon {
		@include rem(width, 14);
		color: $color-black-5;
		@include vertical-align-absolute();
		@include rem(left, 12);
		z-index: 3;
	}

	dx-text-box {
		@include rem(font-size, 12);

		.dx-texteditor-input {
			@include rem(min-height, 30);
			@include rem(padding, 5, 7, 6, 34);
		}

		.dx-placeholder {
			@include rem(left, 26);

			&:before {
				@include rem(padding, 5, 7, 6);
			}
		}
	}
}

/*
	Example:
	<dx-text-box class="input-create"
				[(value)]="projectEditing.name"
				placeholder="{{isProjectWorkflow ? 'Nhập tên quy trình...' : !isProjectWorkflow ? 'Nhập tên dự án...' : ''}}">
	</dx-text-box>
*/
.input-create.dx-texteditor.dx-editor-outlined {
	@include rem(font-size, 24);
	border-color: transparent;

	&:hover {
		background-color: $color-black-1;
	}

	&:focus-within {
		border-color: $color-black-1;

		&:hover {
			background-color: $color-black-1;
		}
	}

	&.dx-state-focused {
		box-shadow: none;
		background-color: $color-black-1;
	}

	.dx-texteditor-input {
		@include rem(padding, 7, 9, 8);
		font-weight: 600;
	}
}

/*
	text-box search. example:
	<div class="input-search">
		<app-svg-icon class="icon-search"
			[name]="'icon_search'">
		</app-svg-icon>
		<dx-text-box
			[showClearButton]="false"
			[(value)]="searchTemp"
			placeholder="Tìm kiếm..."
			valueChangeEvent="keyup"
			(onValueChanged)="valueSearchChanged($event)">
		</dx-text-box>
	</div>
*/
.input-search {
	position: relative;

	.icon-search {
		@include rem(width, 14);
		color: $color-black-5;
		@include vertical-align-absolute();
		@include rem(left, 12);
		z-index: 3;
	}

	dx-text-box {
		.dx-texteditor-input {
			@include rem(padding, 8, 9, 8, 34);
		}

		.dx-placeholder {
			@include rem(left, 26);
		}
	}
}

/*
	Example:
	<dx-text-box
		[showClearButton]="true"
		placeholder="Họ và tên"
		[(value)]="userInfo.fullName"
		(valueChange)="onValueChangeName($event)"
		class="input-default">
		<dx-validator>
			<dxi-validation-rule type="required"
				message="Họ và Tên là bắt buộc"></dxi-validation-rule>
		</dx-validator>
	</dx-text-box>
*/
.input-default {
	.dx-texteditor-input {
		@include rem(padding, 9, 10, 10);
	}

	.dx-placeholder {
		@include rem(left, 6);
	}
}

/*
	Example:
	<dx-text-box
		[showClearButton]="true"
		placeholder="Họ và tên"
		[(value)]="userInfo.fullName"
		(valueChange)="onValueChangeName($event)"
		class="input-title">
		<dx-validator>
			<dxi-validation-rule type="required"
				message="Họ và Tên là bắt buộc"></dxi-validation-rule>
		</dx-validator>
	</dx-text-box>
*/
dx-text-box.input-title {
	@include rem(height, 48);

	&.dx-texteditor.dx-editor-outlined {
		border-color: white;
	}

	&.dx-texteditor.dx-editor-outlined.dx-state-hover {
		border-color: $color-black-3;
	}

	&.dx-texteditor.dx-editor-outlined.dx-state-focused {
		border-color: white;
		background: $color-black-1;
	}

	&.dx-texteditor.dx-editor-outlined.dx-state-focused,
	&.dx-htmleditor.dx-state-focused {
		box-shadow: none;
	}

	.dx-texteditor-input {
		font-weight: 600;
		@include rem(font-size, 24);
		@include rem(line-height, 32);
		color: $color-black-10;
		@include rem(padding, 8);
	}

	.dx-placeholder {
		font-weight: 600;
		@include rem(font-size, 24);
	}

	&.input-title-small {
		@include rem(height, 40);

		.dx-texteditor-input {
			@include rem(font-size, 20);
			@include rem(line-height, 24);
		}

		.dx-placeholder {
			@include rem(font-size, 20);
		}
	}

	&.dx-invalid.dx-texteditor,
	&.dx-invalid.dx-texteditor.dx-texteditor.dx-editor-outlined.dx-state-focused,
	&.dx-invalid.dx-texteditor.dx-texteditor.dx-editor-outlined.dx-state-focused,
	&.dx-invalid.dx-texteditor.dx-htmleditor.dx-state-focused {
		border: 1px solid $color-red-5;
		box-shadow: 0 0 0 3px $color-red-2;
	}
}
