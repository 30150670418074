@import '../variables';
@import '../base/mixins';

/**
 * Grid
 */

$grid-color-background: $color-black-0-5;
$grid-header-color: $color-black-7;
$grid-color-row: #ffffff;
$grid-color-row-selected: $color-blue-1;
$grid-border-radius: 8; // px
$grid-row-content-padding: 12; // px

dx-data-grid {
	.dx-datagrid-group-opened,
	.dx-datagrid-group-closed {
		font-size: 25px;
	}

	.dx-datagrid-borders > .dx-datagrid-pager {
		border-top: none;
	}

	.action-inline {
		color: $color-blue-7;

		i {
			cursor: pointer;
			display: inline-block;
			margin: 0 5px;
			font-size: 16px;
		}
	}

	.gray-background {
		background-color: #f7f7f7;
	}

	&.row-hover-cursor-pointer {
		@include unselectable();

		.dx-row.dx-data-row.dx-state-hover {
			cursor: pointer;
		}
	}

	.dx-datagrid-table {
		.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(
				.dx-row-removed
			):not(.dx-edit-row):not(.dx-row-focused)
			> td:not(.dx-focused) {
			background-color: $color-black-1;
		}

		&.dx-datagrid-table-fixed {
			td.dx-pointer-events-none.dx-last-cell {
				border-left: pxToRem(1) solid $color-black-1;
			}
		}
	}

	&.loading {
		.dx-datagrid-pager {
			visibility: hidden;
		}
	}

	.dx-edit-row.dx-datagrid-edit-form {
		.dx-form {
			@media (max-width: 576px) {
				.dx-datagrid-edit-form-item {
					@include flex-direction(column);
				}
			}
		}

		.dx-datagrid-form-buttons-container {
			.dx-button:first-of-type {
				float: right;
				background-color: $color-blue-7;
				border-color: transparent;
				color: #fff;

				&.dx-state-hover {
					background-color: $color-blue-5;
					border-color: transparent;
				}
			}
		}
	}

	.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
		padding: 10px;
	}

	.dx-master-detail-row.dx-adaptive-detail-row {
		.dx-master-detail-cell {
			padding: 10px;

			.dx-layout-manager .dx-field-item:not(.dx-first-row) {
				padding: 0;
			}

			.dx-adaptive-item-text {
				padding-top: 3px;
				padding-bottom: 3px;
				padding-left: 0;
			}

			.dx-form {
				.dx-box-item:not(:first-child) .dx-single-column-item-content > .dx-field-item {
					padding-top: 0;
				}

				.dx-box-item .dx-single-column-item-content > .dx-field-item {
					.dx-field-item-label {
						margin-bottom: 0;
					}

					.dx-field-item-content {
						padding-top: 3px;
						padding-bottom: 3px;
						white-space: normal;
					}
				}
			}
		}
	}

	tr.dx-header-row {
		> td {
			vertical-align: middle !important;
		}

		> td:first-of-type,
		td:nth-of-type(2) {
			position: relative;

			dx-button.btn-add-row-circle {
				padding: 0;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-color: transparent;

				&.dx-state-focused,
				&.dx-state-hover,
				&.dx-state-active {
					border-color: transparent;
				}

				.dx-button-content {
					padding: 2px;

					.dx-icon {
						font-size: 13px;
					}
				}
			}
		}
	}

	.dx-datagrid-pager {
		border-top: 1px solid #ddd;
	}
}

.dx-datagrid-column-chooser {
	.dx-overlay-content {
		.dx-popup-title {
			background-color: $color-blue-7;
		}
	}
}

dx-data-grid.neronn-grid {
	table,
	tbody,
	tr,
	td,
	colgroup,
	col {
		border: none;
	}

	.dx-datagrid {
		.dx-row > td {
			@include rem(padding, $grid-row-content-padding);
			background: transparent;
			color: $color-black-10;
		}
	}

	.dx-datagrid-headers {
		border: none;

		tr.dx-header-row > td:first-child {
			padding-left: pxToRem(20);
		}

		tr.dx-header-row > td:last-child {
			padding-right: pxToRem(20);
		}

		tr.dx-header-row > td {
			div {
				font-weight: 500;
				@include rem(font-size, 12);
				@include rem(line-height, 16);
				color: $grid-header-color;
			}
		}

		&:after {
			content: '';
			position: absolute;
			width: calc(100% - #{pxToRem(24)});
			height: pxToRem(1);
			border-top: pxToRem(1) solid $color-black-1;
			z-index: 999;
			margin-left: pxToRem(12);
		}
	}

	.dx-datagrid-borders > .dx-datagrid-filter-panel,
	.dx-datagrid-borders > .dx-datagrid-headers,
	.dx-datagrid-borders > .dx-datagrid-pager {
		border: none;
	}

	.dx-datagrid-borders > .dx-datagrid-rowsview,
	.dx-datagrid-borders > .dx-datagrid-total-footer {
		border: none;
	}

	.dx-datagrid-borders > .dx-datagrid-headers,
	.dx-datagrid-borders > .dx-datagrid-rowsview,
	.dx-datagrid-borders > .dx-datagrid-total-footer {
		border: none;
	}

	.dx-datagrid .dx-datagrid-headers .dx-row td,
	.dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none,
	.dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td,
	.dx-datagrid .dx-datagrid-rowsview .dx-header-row td,
	.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td {
		border: none;
	}

	.dx-datagrid .dx-column-lines > td {
		border: none;
	}

	// Style for grid body
	div.dx-datagrid-rowsview {
		border-top: 1px solid $color-black-1;
		background: white;
		border-top: none;

		table.dx-datagrid-table {
			tbody > tr {
				cursor: pointer;
				transition: all 0.2s ease-in;
				background: transparent;
				border-top: none;

				&:hover {
					> td {
						background: $color-black-1;
					}
				}

				&.dx-selection > td {
					background: $grid-color-row-selected !important;
					border-bottom: 1px solid transparent !important;
					border-top: 1px solid transparent !important;
				}

				&.dx-freespace-row {
					background: transparent;
					box-shadow: none;
					cursor: default;
					display: none;
				}

				> td {
					vertical-align: middle;
					background: $grid-color-row;
					padding: pxToRem($grid-row-content-padding);
				}

				> td:first-child {
					padding-left: pxToRem(20);
					border-radius: pxToRem(8) 0 0 pxToRem(8);
				}

				> td:last-child {
					border-radius: 0 pxToRem(8) pxToRem(8) 0;
				}
			}
		}

		.dx-row.dx-data-row {
			.row-index {
				display: flex;
				align-items: center;
				justify-content: center;
				@include rem(height, 40);
				margin: 0 auto;
				font-weight: 600;
				@include rem(font-size, 12);
				color: $color-black-5;
			}
		}
	}
}

.cleeksy-simple-grid {
	.dx-datagrid-rowsview .dx-datagrid-content .dx-row {
		border-top: none;
	}

	.dx-row.dx-data-row {
		@include rem(border-bottom, 1);
		border-style: solid;
		border-color: $color-black-3;

		&:first-child {
			@include rem(border-top, 1);
			border-style: solid;
			border-color: $color-black-3;
		}

		> td {
			display: table-cell;
			vertical-align: middle;
			padding: 0;
		}

		> td.dx-command-edit {
			@include rem(min-height, 53);
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-left: auto;
		}
	}

	.dx-datagrid-content .dx-datagrid-table .dx-row.dx-data-row > td,
	.dx-datagrid-content .dx-datagrid-table .dx-row.dx-data-row > td .dx-texteditor-input {
		border: none;
		color: $color-black-10;
	}

	.dx-datagrid-focus-overlay {
		border: none;
	}

	/* Custom Field */
	.item-action-button {
		display: flex;
		align-items: center;
		justify-content: center;
		@include rem(height, 32);
		@include rem(width, 32);
		@include rem(margin-left, 8);
		@include rem(border-radius, 8);
		cursor: pointer;

		.item-icon {
			@include rem(height, 16);
			@include rem(width, 16);
			@include rem(line-height, 14);
			color: $color-black-7;
		}

		.item-icon.trash-icon {
			color: $color-red-5;
		}

		&__save-button {
			background: $color-blue-7;

			.check-icon {
				color: white;
			}
		}
	}

	.name-editing-filed {
		display: flex;
		align-items: center;
		width: 100%;
		padding-right: pxToRem(10);

		.item-icon.icon-update {
			@include rem(height, 16);
			@include rem(width, 16);
			color: $color-black-3;
			@include rem(margin-right, 7);
			flex-shrink: 0;
		}

		.update-input {
			flex: auto;
			border: 1px solid transparent !important;

			&.dx-state-focused {
				border: 1px solid $color-blue-7 !important;
			}
		}
	}
}
