dx-number-box.dx-numberbox {
	input.dx-texteditor-input {
		@include rem(min-height, 30);
	}

	// <dx-number-box class="select-box-small></dx-number-box>
	&.number-box-small {
		@include rem(height, 32);

		.dx-placeholder::before {
			@include rem(padding, 5, 9);
			@include rem(padding-left, 34);
		}
	}

	// <dx-number-box class="number-box-large></dx-number-box>
	&.number-box-large {
		@include rem(height, 44);
	}

	// Icon visible
	// <dx-number-box class="icon-visible></dx-number-box>
	&.icon-visible .dx-texteditor-input,
	&.icon-visible .dx-placeholder::before {
		@include rem(padding-left, 30);
	}

	&.dx-texteditor.dx-editor-outlined {
		&.dx-state-focused {
			.dx-placeholder {
				display: initial;
			}
		}
	}
}
