@use 'sass:math';

// Zoom
$min-zoom-value: 10;
$max-zoom-value: 200;
@for $i from $min-zoom-value through $max-zoom-value {
	.zoom-#{$i} {
		//zoom: percentage(math.div($i, 100)) !important;
		// -moz-transform: scale(percentage(math.div($i, 100))) !important;
		transform: scale3d(
			percentage(math.div($i, 100)),
			percentage(math.div($i, 100)),
			1
		) !important;

		// NOTE: This require to fix scrollbar not update
		transform-origin: 0 0;

		// Firefox fallback - Don't use now
	}
}
