$animation-time: 2s;

$layout-background: linear-gradient(
	to right,
	rgba(255, 255, 255, 0.2) 0%,
	rgba(255, 255, 255, 0.8) 50%,
	rgba(255, 255, 255, 0.2) 100%
);

//$layout-background2: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.6));

.skeleton-loader {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: $color-black-2;
	border-radius: pxToRem(4);
	overflow: hidden;

	&.skeleton-circle {
		border-radius: 50%;

		&:after {
			width: 100%;
		}
	}

	&:after {
		content: '';
		width: 30%;
		height: 100%;
		position: absolute;
		top: 0;
		left: -110%;
		background: $layout-background;
		animation: flash $animation-time ease-in-out infinite;
		box-shadow:
			0 0 pxToRem(10) rgba(255, 255, 255, 0.1),
			0 0 pxToRem(20) rgba(255, 255, 255, 0.1),
			0 0 pxToRem(40) rgba(255, 255, 255, 0.1);
	}
}

@keyframes flash {
	0% {
		left: -110%;
	}
	100% {
		left: 110%;
	}
}
