// Variables
@import 'variables/shadow';
@import 'variables/container-size';

@import 'cui/button';

/*Text heading*/
h1,
.text-h1 {
	@include rem(font-size, 52);
	@include rem(line-height, 72);
}

h2,
.text-h2 {
	@include rem(font-size, 44);
	@include rem(line-height, 56);
}

h3,
.text-h3 {
	@include rem(font-size, 36);
	@include rem(line-height, 44);
}

h4,
.text-h4 {
	@include rem(font-size, 28);
	@include rem(line-height, 36);
}

h5,
.text-h5 {
	@include rem(font-size, 24);
	@include rem(line-height, 32);
}

h6,
.text-h6 {
	@include rem(font-size, 20);
	@include rem(line-height, 24);
}
