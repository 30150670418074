.dx-tooltip-wrapper .dx-overlay-content {
	background-color: $color-black-10;
	color: white;
	box-shadow: none;
	border: none;
}

.dx-tooltip-wrapper.dx-popover-wrapper {
	.dx-popover-arrow {
		display: block;
		@include rem(height, 10);
		@include rem(width, 20);
	}

	&.dx-position-left,
	&.dx-position-right {
		.dx-popover-arrow {
			@include rem(height, 16);
			@include rem(width, 8);
		}
	}
}

.dx-tooltip-wrapper.dx-popover-wrapper {
	.dx-popover-arrow::after {
		@include rem(height, 10);
		@include rem(width, 20);
		background-color: $color-black-10;
		border: none;
	}

	&.dx-position-top {
		.dx-popover-arrow::after {
			top: pxToRem(-2);
		}
	}

	&.dx-position-bottom {
		.dx-popover-arrow::after {
			top: pxToRem(2);
		}
	}

	&.dx-position-left,
	&.dx-position-right {
		.dx-popover-arrow::after {
			@include rem(height, 16);
			@include rem(width, 8);
		}
	}
}

.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
	@include rem(border-radius, 8);
	@include rem(font-size, 12);
	@include rem(line-height, 18);
	@include rem(padding, 12);
	white-space: normal;
	text-align: left;
	width: 100%;

	.truncate-text {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-word;
		white-space: normal;
	}
}
