@import '../variables';
@import '../base/mixins';

.dx-toast-stack {
	.dx-toast-content {
		@include rem(max-height, 56);
		@include rem(min-height, 46);

		width: auto !important;
		@include rem(min-width, 200);
		@include rem(max-width, 375);

		@include rem(gap, 16);
		@include rem(padding, 12, 16, 12, 16);
		@include rem(border-radius, 8);

		&.dx-toast-success {
			background: $color-green-5;
		}

		&.dx-toast-info {
			background: $color-blue-5;
		}

		&.dx-toast-error {
			background: $color-red-5;
		}

		&.dx-toast-warning {
			background: $color-yellow-5;
		}

		.dx-toast-icon {
			@include rem(height, 20);
			@include rem(width, 20);
			margin: 0;
		}

		.dx-toast-message {
			flex: 1;

			font-style: normal;
			font-weight: 500;

			@include rem(font-size, 12);
			@include rem(line-height, 16);
			@include text-truncate(2);
		}
	}
}
