.label-name {
	@include vertical-align-flex();
	@include rem(line-height, 16);
	@include rem(height, 20);
	position: relative;
	background-color: var(--label-color);
	color: #fff;
	z-index: 1;
	@include rem(padding, 2, 0, 2, 8);
	@include borderRadius(20px);
	@include rem(font-size, 12);
	white-space: nowrap;
	text-overflow: ellipsis;

	&--text {
		display: inline-block;
		color: var(--label-text);
		@include rem(padding-right, 8);
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.icon-close {
		@include rem(margin-right, 2);

		app-svg-icon {
			color: var(--label-button);
		}
	}
}
