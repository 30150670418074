@import '../variables';
@import '../base/mixins';

// TODO: Need to refactor
.dx-popup-wrapper.dx-datebox-wrapper,
.dx-popup-wrapper.dx-scheduler-navigator-calendar-popover {
	.dx-overlay-content {
		padding: 0;
		margin: 0;

		> .dx-popup-content {
			padding: 24px 16px;
			margin: 0;

			.dx-calendar-view-decade,
			.dx-calendar-view-year {
				padding: 0;
				margin: 0;

				.dx-widget.dx-calendar-navigator {
					> div {
						padding: 0;
						margin: 0;
						border: 0;
						border-radius: 6px;

						&.dx-button.dx-calendar-navigator-next-view,
						&.dx-button.dx-calendar-navigator-previous-view {
							@include rem(height, 36);
							@include rem(width, 36);
						}

						&.dx-calendar-caption-button {
							.dx-icon,
							.dx-button-text {
								color: $color-black-6;

								@include rem(font-size, 14);
								font-weight: 500;
								text-transform: capitalize;
							}
						}

						span,
						i {
							font-size: 13px;
							font-style: normal;
						}

						.dx-icon,
						.dx-button-text {
							color: $color-black-3;
						}
					}
				}

				.dx-calendar-body {
					thead tr th {
						@include rem(font-size, 12);
						text-align: center;
						box-shadow: none;
					}

					tbody {
						td {
							padding: 0;
							margin: 0;
							text-align: center;
							vertical-align: middle;

							&.dx-calendar-other-month {
								> span {
									color: $color-black-4;
								}
							}

							> span {
								@include inline-flex();
								@include align-items(center);
								@include justify-content(center);

								height: 70px;
								width: 70px;
								line-height: unset !important;
								box-shadow: none;
								margin: 0;
								border-radius: 50%;

								@include rem(font-size, 12);
								color: $color-black-6;
							}

							&.dx-state-hover,
							&.dx-state-active {
								background: white;
								box-shadow: none !important;

								> span {
									color: $color-blue-6;
									background: $color-blue-2;
								}
							}

							&.dx-calendar-selected-date {
								box-shadow: none;

								> span {
									background: $color-blue-6;
									color: white;
									border: 2px solid $color-blue-6;
								}
							}
						}
					}
				}
			}

			.dx-calendar.dx-calendar-view-month {
				padding: 0;
				margin: 0;

				.dx-widget.dx-calendar-navigator {
					> div {
						padding: 0;
						margin: 0;
						border: 0;
						border-radius: 6px;

						&.dx-button.dx-calendar-navigator-next-view,
						&.dx-button.dx-calendar-navigator-previous-view {
							@include rem(height, 36);
							@include rem(width, 36);
						}

						&.dx-calendar-caption-button {
							.dx-icon,
							.dx-button-text {
								color: $color-black-6;
								@include rem(font-size, 14);
								font-weight: 500;
								text-transform: capitalize;
							}
						}

						span,
						i {
							font-size: 13px;
							font-style: normal;
						}

						.dx-icon,
						.dx-button-text {
							color: $color-black-3;
						}
					}
				}

				.dx-calendar-body {
					thead tr th {
						@include rem(font-size, 12);
						text-align: center;
						box-shadow: none;
					}

					tbody {
						td {
							padding: 0;
							margin: 0;
							vertical-align: middle;
							text-align: center;

							&.dx-calendar-other-month {
								> span {
									color: $color-black-4;
								}
							}

							> span {
								@include inline-flex();
								@include align-items(center);
								@include justify-content(center);

								@include rem(height, 32);
								@include rem(width, 32);
								box-shadow: none;
								border-radius: 50%;
								color: $color-black-6;
								margin: auto;
								@include rem(font-size, 12);
								font-weight: 500;
							}

							&.dx-state-hover,
							&.dx-state-active {
								background: white;
								box-shadow: none !important;

								> span {
									color: $color-blue-6;
									background: $color-blue-2;
								}
							}

							&.dx-calendar-selected-date {
								box-shadow: none;

								> span {
									background: $color-blue-6;
									color: white;
									border: #{pxToRem(2)} solid $color-blue-6;
								}
							}

							//&.dx-calendar-selected-date {
							//	color: $color-blue-7;
							//	border: none;
							//	background: white;
							//}
						}
					}
				}
			}
		}
	}
}

.dx-overlay-wrapper.dx-datebox-wrapper-time {
	.dx-popup-content {
		@include rem(padding, 8 !important);

		.dx-item.dx-list-item {
			@include rem(border-radius, 8);
		}
	}
}
