/**
 * Switch
 */

dx-switch {
	.dx-switch-container {
		border-radius: 20px;
		@include rem(width, 34);
		@include rem(height, 18);
		padding: 0 !important;

		.dx-switch-inner {
			@include rem(height, 18);

			.dx-switch-on,
			.dx-switch-off {
				color: transparent;
			}

			.dx-switch-handle {
				@include rem(width, 16);
				@include rem(height, 16);
				flex-basis: 0px;
				flex-shrink: 0 !important;
				margin: 0px !important;

				&::before {
					@include rem(width, 16);
					@include rem(height, 16);
					border-radius: 50%;
				}
			}
		}
	}

	//
	&.dx-switch {
		&.dx-state-focused,
		&.dx-state-hover,
		&.dx-state-focused.dx-state-active {
			.dx-switch-handle:before {
				background-color: #fff;
			}

			.dx-switch-container {
				background: $color-black-3;
				border-color: $color-black-3;
			}
		}

		.dx-switch-container {
			background-color: $color-black-3;
		}

		.dx-switch-handle:before {
			background-color: #fff;
		}

		&.dx-switch-on-value {
			&.dx-state-focused {
				.dx-switch-handle:before {
					background-color: #fff;
				}
			}

			.dx-switch-handle:before {
				background-color: #fff;
			}

			.dx-switch-container {
				border-color: $color-blue-7;
				background-color: $color-blue-7;
			}
		}
	}

	&.dx-switch.dx-state-readonly.dx-switch-on-value > .dx-switch-wrapper > .dx-switch-container {
		border-color: $color-blue-4;
		background-color: $color-blue-4;
	}
}
