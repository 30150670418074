$transition: all 0.1s ease-in-out;
.dashboard-card-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: $color-black-0-5;
	border: 1px solid $color-black-2;
	@include border-radius(12px);
	transition: $transition;
	overflow: hidden;
	&:hover {
		background-color: #fff;
		border: 1px solid $color-black-4;
		filter: drop-shadow(0px 4px 12px rgba(40, 46, 62, 0.12));
	}
}
// Text style
.dashboard-heading-24 {
	font-style: normal;
	font-weight: 600;
	@include rem(font-size, 24);
	@include rem(line-height, 32);
	letter-spacing: -0.02em;
	@include minmax-breakpoint(1024px, 1440px) {
		font-size: clamp(1.5vw, 1.5vw + 0.2rem, 1.5rem);
	}
}
.dashboard-heading-20 {
	font-style: normal;
	font-weight: 600;
	@include rem(font-size, 20);
	@include rem(line-height, 24);
	letter-spacing: -0.02em;
	@include minmax-breakpoint(1024px, 1440px) {
		font-size: clamp(1.25vw, 1.25vw + 0.2rem, 1.25rem);
	}
}
.dashboard-heading-16 {
	font-style: normal;
	font-weight: 600;
	@include rem(font-size, 16);
	@include rem(line-height, 20);
	letter-spacing: -0.02em;
	@include minmax-breakpoint(1024px, 1440px) {
		font-size: clamp(1vw, 1vw + 0.2rem, 1rem);
	}
}
.dashboard-heading-14 {
	font-style: normal;
	font-weight: 600;
	@include rem(font-size, 14);
	@include rem(line-height, 20);
	letter-spacing: -0.02em;
	@include minmax-breakpoint(1024px, 1440px) {
		font-size: clamp(0.875vw, 0.875vw + 0.1rem, 0.875rem);
	}
}
.dashboard-heading-12 {
	font-style: normal;
	font-weight: 600;
	@include rem(font-size, 12);
	@include rem(line-height, 16);
	letter-spacing: -0.02em;
	@include minmax-breakpoint(1024px, 1440px) {
		font-size: clamp(0.75vw, 0.75vw + 0.1rem, 0.75rem);
	}
}
.dashboard-text-16 {
	font-style: normal;
	font-weight: normal;
	@include rem(font-size, 16);
	@include rem(line-height, 20);
	@include minmax-breakpoint(1024px, 1440px) {
		font-size: clamp(1vw, 1vw + 0.1rem, 1rem);
	}
}
.dashboard-text-14 {
	font-style: normal;
	font-weight: normal;
	@include rem(font-size, 14);
	@include rem(line-height, 20);
	@include minmax-breakpoint(1024px, 1440px) {
		font-size: clamp(0.875vw, 0.875vw + 0.1rem, 0.875rem);
	}
}
.dashboard-text-12 {
	font-style: normal;
	font-weight: normal;
	@include rem(font-size, 12);
	@include rem(line-height, 16);
	@include minmax-breakpoint(1024px, 1440px) {
		font-size: clamp(0.75vw, 0.75vw + 0.1rem, 0.75rem);
	}
}
