// ----------------------------------------------------------------------

// Transitions

// ----------------------------------------------------------------------

// example: @include single-transition(background, 1s, ease-in-out, 0);

@mixin single-transition(
	$property: all,
	$duration: 0.3s,
	$timing-function: ease-in-out,
	$delay: 0
) {
	-webkit-transition: $property $duration $timing-function;
	-moz-transition: $property $duration $timing-function;
	-o-transition: $property $duration $timing-function;
	transition: $property $duration $timing-function;

	transition-delay: $delay;
	-webkit-transition-delay: $delay;
	-moz-transition-delay: $delay;
	-o-transition-delay: $delay;
}

// example: @include double-transition(background, 1s, ease-in-out, 0, opacity, .1s, ease-in-out, 0);

@mixin double-transition(
	$property1,
	$duration1,
	$timing-function1,
	$delay1,
	$property2,
	$duration2,
	$timing-function2,
	$delay2
) {
	-webkit-transition:
		$property1 $duration1 $timing-function1 $delay1,
		$property2 $duration2 $timing-function2 $delay2;
	-moz-transition:
		$property1 $duration1 $timing-function1 $delay1,
		$property2 $duration2 $timing-function2 $delay2;
	-o-transition:
		$property1 $duration1 $timing-function1 $delay1,
		$property2 $duration2 $timing-function2 $delay2;
	transition:
		$property1 $duration1 $timing-function1 $delay1,
		$property2 $duration2 $timing-function2 $delay2;
}

@mixin transition($string) {
	-webkit-transition: $string;
	-moz-transition: $string;
	-o-transition: $string;
	transition: $string;
}
