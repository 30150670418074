@import '../base/mixins';
@import '../variables';

@mixin text-style($fontFml, $fontStyle, $fontWeight, $fontSize, $lineHeight, $letterSpace, $color) {
	font-family: $fontFml;
	font-style: $fontStyle;
	font-weight: $fontWeight;
	font-size: $fontSize;
	line-height: $lineHeight;
	letter-spacing: $letterSpace;
	color: $color;
}

@mixin icon-text {
	display: flex;
	height: 30px;
	padding: 2px;
	position: relative;
	align-self: center;
	border-radius: 7px;
}

.neronn-grid-form {
	.dx-datagrid {
		background-color: #fff !important;

		.dx-row > td[role='columnheader'] {
			background-color: #fff !important;
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	.dx-datagrid-table {
		.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(
				.dx-row-removed
			):not(.dx-edit-row):not(.dx-row-focused)
			> td:not(.dx-focused) {
			background-color: #fff !important;
			transition: all 0.5s ease-out !important;
		}
	}

	.dx-datagrid-rowsview {
		.dx-scrollable-content {
			min-height: 115px;
		}

		table {
			tr {
				box-shadow: 0px 2px 4px 0px #282e3e14;
				cursor: pointer;
				background: $grid-color-row;
				@include rem(border-radius, $grid-border-radius);

				&:hover {
					// override grid.css
					box-shadow: 0px 2px 4px 0px #282e3e14;
					background: $grid-color-row;
				}
			}

			border-spacing: 0 8px;
			border-collapse: separate;

			td:first-child {
				border-left-style: solid #e6e9f0;
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
			}

			td:last-child {
				border-right-style: solid #e6e9f0;
				border-bottom-right-radius: 10px;
				border-top-right-radius: 10px;
			}
		}

		table td {
			height: 70px;
			padding: 18px 0px;
			border-top: 1px solid #e6e9f0 !important;
			border-bottom: 2px solid #e6e9f0 !important;
			color: #666e83;
		}

		table td:first-child {
			border-left: 1px solid #e6e9f0 !important;
		}

		table td:last-child {
			border-right: 1px solid #e6e9f0;
		}

		.dx-datagrid-focus-overlay {
			border: none;
		}
	}

	.dx-datagrid-headers {
		border-bottom: none;

		> .dx-datagrid-content > .dx-datagrid-table {
			padding: 0;
		}

		td {
			border: none !important;

			.dx-datagrid-text-content {
				color: #a1a9bb !important;
				font-size: 12px !important;
				font-weight: 700;
				line-height: 16px;
			}
		}
	}

	.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
		.dx-checkbox-icon {
			display: none;
		}
	}

	.dx-pager {
		border: none;
		padding-bottom: 0;
	}

	.dx-pages {
		float: left;
		bottom: -34px;
		left: 24px;
		position: absolute;

		.dx-page {
			padding: 7px 9px 8px;
			border-radius: 5px;
			background-color: transparent;
			border: 1px solid #e6e9f0;
			padding: 7px 12px;
			text-align: center;

			&.dx-selection {
				background-color: #5c9bf9;
				border-radius: 5px;
				color: white;
			}
		}

		.dx-navigate-button {
			background-color: transparent;
			width: 4px;
			height: 12px;
			color: #666e83;
			padding: 9px 14px;
			border: 1px solid #e6e9f0;
			border-radius: 5px;
		}

		.dx-next-button {
			margin-left: 5px;
		}
	}

	// Style for cell
	.dx-datagrid-rowsview {
		.dx-row.dx-data-row {
			.row-index {
				width: 32px;
				display: flex;
				height: 32px;
				margin: 0 auto;
				background: #eff7ff;
				border-radius: 5px;
				align-items: center;
				justify-content: center;
				color: #2c56ea;
				font-size: 12px;
				font-weight: 700;
			}

			.document-no {
				background: $color-black-0-5;
				border: 1px solid #a1a9bb;
				box-sizing: border-box;
				border-radius: 4px;
				padding: 2px 4px;
				width: fit-content;

				.document-no-text {
					@include text-style(Inter, normal, 700, 12px, 16px, 0, #a1a9bb);
				}
			}

			dx-text-box,
			dx-select-box,
			.dx-numberbox,
			.dx-texteditor {
				border: 1px solid #d9dee8;
				border-radius: 6px;

				.dx-state-focused {
					border-color: #2171c1;
				}

				.dx-state-hover {
					border-color: rgba(33, 113, 193, 0.4);
				}

				input {
					@include text-style(Inter, normal, 400, 14px, 20px, normal, #282e3e);
				}

				.dx-placeholder {
					@include text-style(Inter, normal, 400, 14px, 20px, normal, #a1a9bb);
				}
			}

			.dx-checkbox-container,
			.dx-checkbox-icon {
				border-radius: 4px;
			}
		}
	}
}
