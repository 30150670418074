dx-select-box.dx-selectbox {
	&.dx-show-clear-button .dx-icon-clear {
		@include rem(font-size, 16);
		color: $color-black-3;
	}

	// <dx-select-box class="select-box-small></dx-select-box>
	&.select-box-small {
		@include rem(height, 32);

		.dx-texteditor-input {
			@include rem(min-height, 30);
		}

		.dx-placeholder::before {
			@include rem(padding, 5, 9);
			@include rem(padding-left, 34);
		}
	}

	// <dx-select-box class="select-box-large></dx-select-box>
	&.select-box-large {
		@include rem(height, 44);
	}

	// Icon visible
	// <dx-select-box class="icon-visible></dx-select-box>
	&.icon-visible .dx-texteditor-input,
	&.icon-visible .dx-placeholder::before {
		@include rem(padding-left, 30);
	}

	&.dx-texteditor.dx-editor-outlined {
		&.dx-state-focused {
			.dx-placeholder {
				display: initial;
			}
		}
	}

	&.without-border.dx-texteditor {
		border: none;
	}

	.dx-dropdowneditor-button {
		width: 24px;
		min-width: 24px;
	}

	.dx-dropdowneditor-icon {
		@include rem(width, 16);
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent !important;

		&:before {
			content: '';
			position: static;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNiA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMC43MDUgMC4yOTAwMzlMMyAyLjU4NTA0TDUuMjk1IDAuMjkwMDM5TDYgMS4wMDAwNEwzIDQuMDAwMDRMMCAxLjAwMDA0TDAuNzA1IDAuMjkwMDM5WiIgZmlsbD0iIzU4NjY4OSIvPgo8L3N2Zz4K);
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
			display: inline-block;
			vertical-align: middle;
			height: 100%;
			@include rem(width, 12);
			margin: 0;
		}
	}

	// Type: Normal without default background
	&[aria-type='normal'] {
		color: $color-black-7;
		border: none !important;
		border-radius: 6px !important;

		&.dx-state-hover {
			background-color: $color-black-1;
		}

		&.dx-state-active {
			background-color: $color-black-1;
		}

		.dx-texteditor-input {
			padding: 7px 8px;
		}

		.dx-texteditor-buttons-container {
			padding-right: 1px;
		}
	}

	// Type: Normal with default background
	&[aria-type='normal-weight'] {
		color: $color-black-7;
		border: none !important;
		border-radius: 6px !important;
		background-color: $color-black-1 !important;

		&.dx-state-hover {
			background-color: $color-black-2 !important;
		}

		&.dx-state-active {
			background-color: $color-black-2 !important;
		}

		.dx-texteditor-input {
			padding: 7px 8px;
		}

		.dx-texteditor-buttons-container {
			padding-right: 1px;
		}
	}
}

.dx-selectbox-popup-wrapper {
	&.dx-dropdownlist-popup-wrapper
		.dx-list:not(.dx-list-select-decorator-enabled)
		.dx-list-item-content {
		@include rem(padding, 8, 6, 8, 8);
	}

	&.dx-dropdownlist-popup-wrapper {
		.dx-overlay-content {
			@include rem(border-radius, 8);
			@include rem(min-width, 300);
		}

		.dx-popup-content {
			@include rem(padding, 4);
		}
	}

	.dx-list {
		.dx-empty-message {
			border-top: none;
		}

		.dx-list-item-content {
			position: relative;
			// padding: 8px 6px 8px 36px;
			@include rem(padding, 8, 6, 8, 8);
			font-family: inherit;
			line-height: 20px;
			color: $color-black-7;

			&:before {
				// content: "";
				content: none;
				display: none;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDExIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQo8cGF0aCBkPSJNMy42NjY2OSA2LjM5MDY4TDEuNDcxMzUgNC4xOTUzNUwwLjUyODY4NyA1LjEzODAxTDMuNjY2NjkgOC4yNzYwMUwxMC4xMzggMS44MDQ2OEw5LjE5NTM1IDAuODYyMDE1TDMuNjY2NjkgNi4zOTA2OFoiIGZpbGw9IiM2QTc5QTAiLz4NCjwvc3ZnPg0K);
				background-repeat: no-repeat;
				background-position: center center;
				background-size: contain;
				vertical-align: middle;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 16px;
				margin: 0 6px 0 8px;
				height: 16px;
				border: 2px solid transparent;
				border-bottom-width: 3px;
				float: none;
			}
		}

		.dx-scrollable-content {
			padding-right: 0 !important;
		}

		.dx-list-item {
			border-top: none;
			border-radius: 5px;
			margin-top: 4px;

			&:first-child {
				margin-top: 0;
			}

			&.dx-list-item-selected,
			&.dx-state-focused.dx-list-item-selected,
			&.dx-state-active {
				background-color: $color-black-1;
			}

			&.dx-state-hover {
				background-color: $color-black-1;
			}

			&.dx-state-focused {
				background-color: $color-black-1;
			}

			&.dx-state-active {
				background-color: $color-black-1;
			}

			&.dx-list-item-selected {
				background-color: $color-black-1;

				.dx-list-item-content:before {
					display: inline-block;
				}

				&.dx-state-active {
					background-color: $color-black-1;
				}

				&.dx-state-hover:not(.dx-state-focused) {
					background-color: $color-black-1;
				}

				&.dx-state-focused {
					background-color: $color-black-1;
				}
			}
		}
	}

	.dx-item.dx-list-item-selected .dx-list-item-before-bag.dx-list-select-radiobutton-container {
	}

	.dx-list-item-before-bag {
		display: none;
	}
}

.dx-selectbox-popup-wrapper.cui-select-box-popup-wrapper {
	.dx-list .dx-list-item.dx-list-item-selected {
		border-radius: pxToRem(8);
		background-color: $color-blue-1;
		color: $color-blue-9;

		app-svg-icon,
		span {
			color: $color-blue-9;
		}
	}
}
