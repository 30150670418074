/*
	Example:
	<div class="box-item hover-close">
		<div class="box-item--icon">
			<div class="box-icon" [ngStyle]="{backgroundColor: todo.color || '#DA70D6'}">
				<i class="svg-icon" [ngClass]="todo.icon || 'fas fa-list'"></i>
			</div>
		</div>
		<div class="box-item--content">
			<h6>{{todo.name}}</h6>
			<p>{{todo.description}}</p>
		</div>
		<app-svg-icon class="box-item--close"
						(click)="removeItemTodo($event, todo)"
						[name]="'icon_close_circle_background'">
		</app-svg-icon>
	</div>
*/

.box-item {
	@include vertical-align-flex();
	&.hover-close {
		@include rem(padding, 10, 20, 10, 10);
		position: relative;
		&:hover,
		&.active {
			background-color: $color-black-1;
			@include borderRadius(10px);
			.box-item--close {
				display: inline-block;
			}
		}
	}
	&--icon {
		.box-icon {
			position: relative;
			@include borderRadius(6px);
			@include rem(width, 48);
			@include rem(height, 48);

			.svg-icon {
				color: #fff;
				@include rem(font-size, 14);
				@include center-align-absolute();
			}
		}
	}

	&--content {
		width: 100%;
		overflow: hidden;
		@include rem(padding, 0, 10);

		h6,
		p {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		h6 {
			color: $color-black-7;
		}

		p {
			color: $color-black-5;
		}
	}
	&--close {
		cursor: pointer;
		display: none;
		@include vertical-align-absolute();
		@include rem(right, 10);
		@include rem(width, 12);
		color: $color-black-5;
		&:hover {
			color: $color-red-5;
		}
	}
}

.box-field {
	@include rem(margin-bottom, 24);
	.box-member {
		@include rem(padding, 10);
		@include borderRadius(6px);
		@include vertical-align-flex();
		transition: background-color 0.2s ease-in-out;
		&:hover {
			background-color: $color-black-1;
		}
	}
}

// <div class="box-select">content</div>
.box-select {
	@include borderRadius(6px);
	@include rem(padding, 5, 7);
	display: inline-block;
	cursor: pointer;
	&:hover {
		background-color: $color-black-1;
	}
}
