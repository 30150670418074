/** This file contain all bootstrap css that use in Cleeksy
* and this is obsoleted class will be removed soon.
* DON"T USE them in any new UI screens
*/

*,
*::before,
*::after {
	box-sizing: border-box;
}

/*Heading*/
h1,
.h1 {
	font-size: 2.5rem;
}

h2,
.h2 {
	font-size: 2rem;
}

h3,
.h3 {
	font-size: 1.75rem;
}

h4,
.h4 {
	font-size: 1.5rem;
}

h5,
.h5 {
	font-size: 1.25rem;
}

h6,
.h6 {
	font-size: 0.9rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

/*Container*/
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 576px) {
	.container,
	.container-sm {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container,
	.container-sm,
	.container-md {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg {
		max-width: 960px;
	}
}

@media (min-width: 1200px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl {
		max-width: 1140px;
	}
}

/*Row and col*/
.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}

.col {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
}

.col-auto {
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

.col-1 {
	-ms-flex: 0 0 8.333333%;
	flex: 0 0 8.333333%;
	max-width: 8.333333%;
}

.col-2 {
	-ms-flex: 0 0 16.666667%;
	flex: 0 0 16.666667%;
	max-width: 16.666667%;
}

.col-3 {
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%;
}

.col-4 {
	-ms-flex: 0 0 33.333333%;
	flex: 0 0 33.333333%;
	max-width: 33.333333%;
}

.col-5 {
	-ms-flex: 0 0 41.666667%;
	flex: 0 0 41.666667%;
	max-width: 41.666667%;
}

.col-6 {
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}

.col-7 {
	-ms-flex: 0 0 58.333333%;
	flex: 0 0 58.333333%;
	max-width: 58.333333%;
}

.col-8 {
	-ms-flex: 0 0 66.666667%;
	flex: 0 0 66.666667%;
	max-width: 66.666667%;
}

.col-9 {
	-ms-flex: 0 0 75%;
	flex: 0 0 75%;
	max-width: 75%;
}

.col-10 {
	-ms-flex: 0 0 83.333333%;
	flex: 0 0 83.333333%;
	max-width: 83.333333%;
}

.col-11 {
	-ms-flex: 0 0 91.666667%;
	flex: 0 0 91.666667%;
	max-width: 91.666667%;
}

.col-12 {
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}

@media (min-width: 576px) {
	.col-sm {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-sm-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-sm-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.col-sm-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.col-sm-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-sm-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-sm-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.col-sm-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-sm-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.col-sm-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-sm-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-sm-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.col-sm-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.col-sm-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}

@media (min-width: 768px) {
	.col-md {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-md-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-md-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.col-md-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.col-md-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-md-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-md-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.col-md-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-md-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.col-md-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-md-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-md-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.col-md-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.col-md-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}

@media (min-width: 992px) {
	.col-lg {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-lg-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-lg-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.col-lg-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.col-lg-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-lg-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-lg-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.col-lg-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-lg-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.col-lg-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-lg-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-lg-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.col-lg-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.col-lg-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}

@media (min-width: 1200px) {
	.col-xl {
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}
	.col-xl-auto {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}
	.col-xl-1 {
		-ms-flex: 0 0 8.333333%;
		flex: 0 0 8.333333%;
		max-width: 8.333333%;
	}
	.col-xl-2 {
		-ms-flex: 0 0 16.666667%;
		flex: 0 0 16.666667%;
		max-width: 16.666667%;
	}
	.col-xl-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-xl-4 {
		-ms-flex: 0 0 33.333333%;
		flex: 0 0 33.333333%;
		max-width: 33.333333%;
	}
	.col-xl-5 {
		-ms-flex: 0 0 41.666667%;
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
	}
	.col-xl-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-xl-7 {
		-ms-flex: 0 0 58.333333%;
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	}
	.col-xl-8 {
		-ms-flex: 0 0 66.666667%;
		flex: 0 0 66.666667%;
		max-width: 66.666667%;
	}
	.col-xl-9 {
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-xl-10 {
		-ms-flex: 0 0 83.333333%;
		flex: 0 0 83.333333%;
		max-width: 83.333333%;
	}
	.col-xl-11 {
		-ms-flex: 0 0 91.666667%;
		flex: 0 0 91.666667%;
		max-width: 91.666667%;
	}
	.col-xl-12 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
}

/*Display*/
.clearfix::after {
	display: block;
	clear: both;
	content: '';
}

.d-none {
	display: none;
}

.d-inline {
	display: inline;
}

.d-inline-block {
	display: inline-block;
}

.d-block {
	display: block;
}

.d-table {
	display: table;
}

.d-table-row {
	display: table-row;
}

.d-table-cell {
	display: table-cell;
}

.d-flex {
	display: -ms-flexbox;
	display: flex;
}

.d-inline-flex {
	display: -ms-inline-flexbox;
	display: inline-flex;
}

@media (min-width: 768px) {
	.d-md-none {
		display: none;
	}
	.d-md-inline {
		display: inline;
	}
	.d-md-inline-block {
		display: inline-block;
	}
	.d-md-block {
		display: block;
	}
	.d-md-table {
		display: table;
	}
	.d-md-table-row {
		display: table-row;
	}
	.d-md-table-cell {
		display: table-cell;
	}
	.d-md-flex {
		display: -ms-flexbox;
		display: flex;
	}
	.d-md-inline-flex {
		display: -ms-inline-flexbox;
		display: inline-flex;
	}
}

@media (min-width: 992px) {
	.d-lg-none {
		display: none;
	}
	.d-lg-inline {
		display: inline;
	}
	.d-lg-inline-block {
		display: inline-block;
	}
	.d-lg-block {
		display: block;
	}
	.d-lg-table {
		display: table;
	}
	.d-lg-table-row {
		display: table-row;
	}
	.d-lg-table-cell {
		display: table-cell;
	}
	.d-lg-flex {
		display: -ms-flexbox;
		display: flex;
	}
	.d-lg-inline-flex {
		display: -ms-inline-flexbox;
		display: inline-flex;
	}
}

/*Flex box*/
.flex-row {
	-ms-flex-direction: row;
	flex-direction: row;
}

.flex-column {
	-ms-flex-direction: column;
	flex-direction: column;
}

.flex-row-reverse {
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

.flex-wrap {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.flex-nowrap {
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.flex-grow-1 {
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.flex-shrink-0 {
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.justify-content-start {
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.justify-content-end {
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.justify-content-center {
	-ms-flex-pack: center;
	justify-content: center;
}

.justify-content-between {
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.justify-content-around {
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.align-items-start {
	-ms-flex-align: start;
	align-items: flex-start;
}

.align-items-end {
	-ms-flex-align: end;
	align-items: flex-end;
}

.align-items-center {
	-ms-flex-align: center;
	align-items: center;
}

.align-items-baseline {
	-ms-flex-align: baseline;
	align-items: baseline;
}

.align-items-stretch {
	-ms-flex-align: stretch;
	align-items: stretch;
}

.align-content-start {
	-ms-flex-line-pack: start;
	align-content: flex-start;
}

.align-content-end {
	-ms-flex-line-pack: end;
	align-content: flex-end;
}

.align-content-center {
	-ms-flex-line-pack: center;
	align-content: center;
}

.align-content-between {
	-ms-flex-line-pack: justify;
	align-content: space-between;
}

.align-content-around {
	-ms-flex-line-pack: distribute;
	align-content: space-around;
}

.align-content-stretch {
	-ms-flex-line-pack: stretch;
	align-content: stretch;
}

.align-self-auto {
	-ms-flex-item-align: auto;
	align-self: auto;
}

.align-self-start {
	-ms-flex-item-align: start;
	align-self: flex-start;
}

.align-self-end {
	-ms-flex-item-align: end;
	align-self: flex-end;
}

.align-self-center {
	-ms-flex-item-align: center;
	align-self: center;
}

.align-self-baseline {
	-ms-flex-item-align: baseline;
	align-self: baseline;
}

.align-self-stretch {
	-ms-flex-item-align: stretch;
	align-self: stretch;
}

@media (min-width: 768px) {
	.flex-md-grow-1 {
		-ms-flex-positive: 1;
		flex-grow: 1;
	}
}

@media (min-width: 992px) {
	.flex-lg-column {
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

.position-relative {
	position: relative;
}

.position-absolute {
	position: absolute;
}

.position-fixed {
	position: fixed;
}
