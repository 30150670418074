.input-radio {
	-ms-transform: scale(1.7); /* IE 9 */
	-webkit-transform: scale(1.7); /* Chrome, Safari, Opera */
	transform: scale(1.7);
}

// override radio button devextreme
.dx-radiobutton {
	&.dx-state-hover {
		.dx-radiobutton-icon::before {
			border-color: $color-blue-4;
			background-color: $color-blue-2;
		}
	}

	.dx-radiobutton-icon::before {
		border-color: $color-black-4;
		@include rem(width, 18);
		@include rem(height, 18);
	}

	.dx-radiobutton-icon.dx-radiobutton-icon-checked {
		&:before {
			border-color: $color-blue-3;
			background-color: $color-blue-3;
		}
	}

	.dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
		background-color: $color-blue-7;
	}
}
