@import '../variables';
@import '../base/mixins';

$popover-wrapper-padding: 4;

// Default style
.dx-popover-wrapper {
	&.dx-position-bottom,
	&.dx-position-top {
		&:not(.show-arrow) {
			.dx-popover-arrow {
				@include rem(height, 2);

				&:after {
					@include rem(height, 0);
					@include rem(width, 0);
				}
			}
		}
	}

	&.dx-position-left,
	&.dx-position-right {
		&:not(.show-arrow) {
			.dx-popover-arrow {
				width: 0;
			}
		}
	}

	.dx-overlay-content {
		border: 1px solid $color-black-2;
		@include rem(border-radius, 8);
		box-shadow: $box-shadow-m;
		padding: 0;
		margin: 0;

		&.dx-invalid-message-content {
			@include rem(padding, 10);
			@include rem(border-radius, 4);
		}

		.dx-popup-title {
			@include rem(padding, $popover-wrapper-padding);
			background: #fff;
			@include rem(border-top-left-radius, 10);
			@include rem(border-top-right-radius, 10);
			border-bottom: 1px solid $color-black-3;

			.dx-item-content,
			.dx-icon {
				color: $color-black-7;
				margin: 0;
			}

			.dx-closebutton {
				left: 0;
				@include rem(border-radius, 4);
				border: none;

				&:hover {
					.dx-icon {
						color: $color-red-4;
					}
				}
			}
		}

		.dx-popup-content {
			@include rem(padding, $popover-wrapper-padding);

			margin: 0;
		}
	}
}

// Popover with content no padding
.dx-popover-wrapper.content-no-padding {
	.dx-popup-content {
		@include rem(padding, 0);
	}
}

// Popover with custom showing animation
.custom-animation {
	.dx-overlay-content {
		background: transparent !important;
		box-shadow: none !important;

		.dx-popup-content {
			background: transparent !important;
			box-shadow: none !important;
			overflow: visible;
			padding: 0;

			> * {
				box-shadow: $box-shadow-m;
				@include rem(border-radius, 8);
				background: white;
				overflow: hidden;
			}
		}
	}
}

.title-popover {
	.dx-overlay-content {
		.dx-icon-close {
			color: white;

			&:hover {
				color: red;
			}
		}

		.dx-popup-title.dx-toolbar {
			padding: 0 10px;
		}

		.dx-popup-title.dx-toolbar {
			.dx-toolbar-items-container {
				height: 31px;

				.dx-toolbar-label {
					font-size: 13px;
				}
			}
		}

		.dx-popup-content {
			padding: 10px;

			dx-text-area {
				height: 70px;
			}
		}
	}
}
