// [Obsolete] Exclusive
$color-bright-blue: #1e92ff;
$color-dark-blue: #656669;
$color-menu-gray: #eef0f4;
$color-section-gray: #f7f8fb;

// Blue
$color-blue-0-5: #f3f9ff;
$color-blue-1: #dfecfc;
$color-blue-2: #c8e0fc;
$color-blue-3: #9cc8fc;
$color-blue-4: #70a8fc;
$color-blue-5: #448cfa;
$color-blue-6: #2a6ef0;
$color-blue-7: #195ad9;
$color-blue-8: #0f4bbf;
$color-blue-9: #0e41a1;

// Neutral
$color-white: #fff;

$color-black-0-5: #fafafc;
$color-black-1: #f5f6fa;
$color-black-1-5: #eff2f6; // [Obsolete]
$color-black-2: #e8eaef;
$color-black-2-5: #e8eaef; // [Obsolete]
$color-black-3: #d7dbe4;
$color-black-4: #c5c9d5;
$color-black-5: #a3aabb;
$color-black-6: #7f879c;
$color-black-7: #656d83;
$color-black-8: #4c546a;
$color-black-9: #363d50;
$color-black-10: #282e3e;
$color-black-10-5: #1e2432; // [Obsolete]
$color-black-deep: #161923;

// Green
$color-green-0-5: #ecfdf5;
$color-green-1: #d8f8e9;
$color-green-2: #c0f1db;
$color-green-3: #7ddbb1;
$color-green-4: #35c082;
$color-green-5: #00a55b;
$color-green-6: #01904f;
$color-green-7: #017a43;
$color-green-8: #016437;
$color-green-9: #004e2b;

// Yellow
$color-yellow-0-5: #fdf7eb;
$color-yellow-1: #fcf0d8;
$color-yellow-2: #fbe5bb;
$color-yellow-3: #f9d184;
$color-yellow-4: #f6b842;
$color-yellow-5: #f4a100;
$color-yellow-6: #e28800;
$color-yellow-7: #b76e01;
$color-yellow-8: #975b01;
$color-yellow-9: #794100;

// Red
$color-red-0-5: #fff2f1;
$color-red-1: #fde1df;
$color-red-2: #fcd0cd;
$color-red-3: #faa7a1;
$color-red-4: #f7766c;
$color-red-5: #ea4b3f;
$color-red-6: #d8382c;
$color-red-7: #b93026;
$color-red-8: #9b2920;
$color-red-9: #7b201a;

// Violet
$color-violet-0-5: #f8f5ff;
$color-violet-1: #ece7fc;
$color-violet-2: #e0d7fa;
$color-violet-3: #c9bbf8;
$color-violet-4: #a58bf5;
$color-violet-5: #8463f0;
$color-violet-6: #7354d5;
$color-violet-7: #6146b7;
$color-violet-8: #513a9b;
$color-violet-9: #42307d;

// Orange
$color-orange-0-5: #fff8f3;
$color-orange-1: #fce8dd;
$color-orange-2: #fbd5bf;
$color-orange-3: #f8b38c;
$color-orange-4: #f58d53;
$color-orange-5: #f2691c;
$color-orange-6: #d85a13;
$color-orange-7: #b94c0f;
$color-orange-8: #9a3f0a;
$color-orange-9: #79320a;

// Turquoise
$color-turquoise-0-5: #f1fbfd;
$color-turquoise-1: #d9f4f7;
$color-turquoise-2: #c4eef4;
$color-turquoise-3: #86dce8;
$color-turquoise-4: #3ec5d8;
$color-turquoise-5: #00b3cb;
$color-turquoise-6: #019bb1;
$color-turquoise-7: #018496;
$color-turquoise-8: #006e7d;
$color-turquoise-9: #005763;

// [Obsolete] Jade
$color-jade-1: #effcfb;
$color-jade-2: #ccf4f0;
$color-jade-3: #a9f0eb;
$color-jade-4: #86e4dd;
$color-jade-5: #57d1c8;
$color-jade-6: #22c2b6;
$color-jade-7: #1aaea3;
$color-jade-8: #05857b;
$color-jade-9: #00645c;

// [Obsolete] Turquoise
$color-turquoise-0-5: #f1fbfd;
$color-turquoise-1: #d9f4f7;
$color-turquoise-2: #c4eef4;
$color-turquoise-3: #86dce8;
$color-turquoise-4: #3ec5d8;
$color-turquoise-5: #00b3cb;
$color-turquoise-6: #019bb1;
$color-turquoise-7: #018496;
$color-turquoise-8: #006e7d;
$color-turquoise-9: #005763;
