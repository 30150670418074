/**
 * Scroll View
 */

.dx-scrollable-scrollbars-alwaysvisible.dx-scrollable-vertical .dx-scrollable-content,
.dx-scrollable-scrollbars-alwaysvisible.dx-scrollable-both .dx-scrollable-content {
	padding-right: 0;
}

// example: <div class="scroll-bar"></div>
.scroll-bar {
	&::-webkit-scrollbar {
		@include rem(width, 8);
		@include rem(height, 8);

		&-track {
		}

		&-thumb {
			background: #b4bccf;
			@include borderRadius(10px);

			&:hover {
				background: #c3c3c3;
			}
		}
	}
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
