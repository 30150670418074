@import '../variables';
@import '../base/mixins';

dx-tag-box.dx-tagbox {
	@include rem(min-height, 36);
	@include rem(border-radius, 8);

	.dx-tag-content {
		@include rem(margin, 5, 0, 0, 4);
		@include rem(border-radius, 12);
		@include rem(padding, 2, 28, 2, 8);
		background-color: $color-black-1;

		span {
			font-weight: 400;
			@include rem(font-size, 14);
			@include rem(line-height, 20);
			color: $color-black-10;
		}
	}

	.dx-tag-remove-button {
		position: absolute;
		top: 4px;
		right: 4px;
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background-color: $color-black-3;

		&:hover {
			background-color: $color-black-10;

			&::after,
			&::before {
				background: white;
			}
		}
	}

	.dx-tag-remove-button::after,
	.dx-tag-remove-button::before {
		background: $color-black-5;
	}

	.dx-tag-remove-button::before {
		@include rem(width, 1.5);
		@include rem(right, 7.5);
		@include rem(margin-top, -5);
	}

	.dx-tag-remove-button::after {
		@include rem(height, 1.5);
		@include rem(right, 2.5);
		@include rem(margin-top, -0.5);
		@include rem(top, 8.2);
	}

	.dx-tag-container.dx-texteditor-input-container {
		@include rem(padding, 0);
	}

	.dx-tag-container.dx-texteditor-input-container .dx-texteditor-input {
		@include rem(height, 32);
	}

	.dx-placeholder {
		@include rem(height, 36);
		font-weight: 400;
		@include rem(font-size, 14);
		@include rem(line-height, 20);
	}

	// <dx-tag-box class="tagbox-small"></dx-tag-box>
	&.tagbox-small {
		@include rem(min-height, 32);
	}

	// <dx-tag-box class="tagbox-large"></dx-tag-box>
	&.tagbox-large {
		@include rem(min-height, 80);
	}
}
