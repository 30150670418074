.dx-checkbox {
	.dx-checkbox-icon {
		border-color: $color-black-4;
		@include rem(border-radius, 4);
		color: $color-blue-7;
		@include rem(width, 20);
		@include rem(height, 20);

		@include flexbox();
		@include justify-content(center);
		@include align-items(center);

		&:before {
			padding: 0;
			margin: 0;

			top: 0;
			left: 0;
		}
	}

	&:hover .dx-checkbox-icon {
		border-color: $color-blue-4;
		background: $color-blue-2;
	}

	&.dx-checkbox-checked .dx-checkbox-icon {
		border-color: $color-blue-3;
		background: $color-blue-3;
		position: relative;
	}

	&.checkbox-small .dx-checkbox-icon {
		@include rem(width, 16);
		@include rem(height, 16);
		font: 10px/16px DXIcons;

		&:before {
			@include rem(width, 16);
			@include rem(height, 16);
		}
	}
}

.dx-checkbox-indeterminate {
	.dx-checkbox-icon {
		background-color: $color-blue-7;

		&:before {
			@include rem(height, 2);
			background-color: #fff;
		}
	}
}

/*
	Example:
		<dx-check-box   class="checklist-checkbox"
						(onValueChanged)="onToggleTodoCompleteStatus($event, todo, task)"
						[value]="todo.isDone">
		</dx-check-box>
*/
.checklist-checkbox {
	&.dx-checkbox-checked .dx-checkbox-icon {
		font: 13px/17px DXIcons;
		color: #ddd;
		background-color: $color-green-7;
	}

	.dx-checkbox-icon {
		@include rem(width, 24);
		@include rem(height, 24);
		border-radius: 50%;

		&:before {
			@include rem(width, 24);
			@include rem(height, 24);
		}
	}
}

/*
    Example:
        <dx-check-box   class="checkbox-square"
                        (onValueChanged)="onToggleTodoCompleteStatus($event, todo, task)"
                        [value]="todo.isDone">
        </dx-check-box>
*/
.checkbox-square {
	.dx-checkbox-icon {
		border-color: $color-black-4;
		@include borderRadius(4px);
		@include rem(width, 18);
		@include rem(height, 18);
	}

	&.dx-checkbox-checked .dx-checkbox-icon {
		font: 14px/16px DXIcons;
		color: $color-blue-7;
		background-color: $color-blue-3;
	}
}

/*
    Example:
        <dx-check-box   class="checkbox-cleeksy"
                        (onValueChanged)="onToggleTodoCompleteStatus($event, todo, task)"
                        [value]="todo.isDone">
        </dx-check-box>
*/
.checkbox-cleeksy {
	.dx-checkbox-icon {
		border-color: $color-black-7;
		@include borderRadius(4px);
		@include rem(width, 18);
		@include rem(height, 18);

		&:before {
			@include rem(width, 18);
			@include rem(height, 18);
		}
	}

	&.dx-checkbox-checked .dx-checkbox-icon {
		font: 10px/16px DXIcons;
		color: #fff;
		background-color: $color-blue-7;
	}
}

.checkbox-white-small {
	&.dx-checkbox-checked {
		.dx-checkbox-icon {
			border-color: $color-blue-7;
			background-color: $color-blue-7;
		}
	}

	.dx-checkbox-icon {
		color: white;
		@include rem(width, 16);
		@include rem(height, 16);
		@include rem(font-size, 12);
	}
}
