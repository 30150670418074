/**
 * Text-area
 */
// default _global.scss with class dx-texteditor.dx-editor-outlined

.holder-text-area {
	cursor: text;
	@include rem(padding, 10, 15);
	@include borderRadius(6px);

	&:hover {
		background-color: $color-black-1;
	}
}
