.drawflow,
.drawflow .parent-node {
	position: relative;
}

.parent-drawflow {
	display: flex;
	overflow: hidden;
	touch-action: none;
	outline: 0;
}

.drawflow {
	width: 100%;
	height: 100%;
	user-select: none;
	perspective: 0;
}

.drawflow .drawflow-node {
	display: flex;
	align-items: center;
	position: absolute;
	background: #0ff;
	width: 160px;
	min-height: 40px;
	border-radius: 4px;
	border: 2px solid #000;
	color: #000;
	z-index: 2;
	padding: 15px;
}

.drawflow .drawflow-node.selected {
	background: red;
}

.drawflow .drawflow-node:hover {
	cursor: move;
}

.drawflow .drawflow-node .inputs,
.drawflow .drawflow-node .outputs {
	width: 0;
}

.drawflow .drawflow-node .drawflow_content_node {
	width: 100%;
	display: block;
}

.drawflow .drawflow-node .input,
.drawflow .drawflow-node .output {
	position: relative;
	width: 20px;
	height: 20px;
	background: #fff;
	border-radius: 50%;
	border: 2px solid #000;
	cursor: crosshair;
	z-index: 1;
	margin-bottom: 5px;
}

.drawflow .drawflow-node .input {
	left: -27px;
	top: 2px;
	background: #ff0;
}

.drawflow .drawflow-node .output {
	right: -3px;
	top: 2px;
}

.drawflow svg {
	z-index: 0;
	position: absolute;
	overflow: visible !important;
}

.drawflow .connection {
	position: absolute;
	pointer-events: none;
	aspect-ratio: 1/1;
}

.drawflow .connection .main-path {
	fill: none;
	stroke-width: 5px;
	stroke: #4682b4;
	pointer-events: all;
}

.drawflow .connection .main-path:hover {
	stroke: #1266ab;
	cursor: pointer;
}

.drawflow .connection .main-path.selected {
	stroke: #43b993;
}

.drawflow .connection .point {
	cursor: move;
	stroke: #000;
	stroke-width: 2;
	fill: #fff;
	pointer-events: all;
}

.drawflow .connection .point.selected,
.drawflow .connection .point:hover {
	fill: #1266ab;
}

.drawflow .main-path {
	fill: none;
	stroke-width: 5px;
	stroke: #4682b4;
}

.drawflow-delete {
	position: absolute;
	display: block;
	width: 30px;
	height: 30px;
	background: #000;
	color: #fff;
	z-index: 4;
	border: 2px solid #fff;
	line-height: 30px;
	font-weight: 700;
	text-align: center;
	border-radius: 50%;
	font-family: monospace;
	cursor: pointer;
}

.drawflow > .drawflow-delete {
	margin-left: -15px;
	margin-top: 15px;
}

.parent-node .drawflow-delete {
	right: -15px;
	top: -15px;
}
