/**
* style for link
 */

.cleeksy-link {
	color: $color-blue-7;
	font-weight: 500;
	@include rem(font-size, 12);
	@include rem(line-height, 16);
	cursor: pointer;

	&:hover {
		color: $color-blue-6;
		text-decoration: underline;
	}

	&:active {
		color: $color-blue-8;
	}

	&.large-link {
		@include rem(font-size, 14);
		@include rem(line-height, 20);
	}
}
