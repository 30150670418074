/*-------------SVG Icon-----------*/
.neronn-icon {
	display: inline-block;
	@include rem(width, 16);
	color: $color-black-7;
	line-height: 0;

	svg {
		display: inline-block;
		vertical-align: middle;
		width: 100%;
		height: 100%;
	}
}
