/*
 * Overwrite Bootstrap:
 * TODO: Remove this when completely remote bootstrap
 */
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1440px,
	xxxl: 1600px,
	xxxxl: 1920px,
);

// Default font family
$default-font: 'Inter', sans-serif;

@import 'variables/color';
@import 'variables/shadow';
@import 'variables/container-size';
