/**
//example:
<div class="single-circle">
	<svg viewBox="0 0 36 36" class="circular-chart blue">
		<path class="circle-bg"
		d="M18 2.0845
		a 15.9155 15.9155 0 0 1 0 31.831
		a 15.9155 15.9155 0 0 1 0 -31.831"
		/>
		<path class="circle"
		stroke-dasharray="50, 100"
		d="M18 2.0845
		a 15.9155 15.9155 0 0 1 0 31.831
		a 15.9155 15.9155 0 0 1 0 -31.831"
		/>
	</svg>
</div>
 **/

.single-circle {
	vertical-align: middle;
	display: inline-block;
	@include rem(width, 24);
	justify-content: space-around;
}

.circular-chart {
	display: block;
	margin: 0 auto;
	@include rem(max-height, 100);
}

.circle-bg {
	fill: none;
	stroke: #dadde7;
	stroke-width: 2;
}

.circle {
	fill: none;
	stroke-width: 2;
	stroke-linecap: round;
	animation: progress 1s ease-out forwards;
}

.circular-chart.blue .circle {
	stroke: #2c56ea;
}

.circular-chart.green .circle {
	stroke: #4ec43b;
}

.percentage {
	fill: #666;
	font-family: sans-serif;
	font-size: 0.5em;
	text-anchor: middle;
}

@keyframes progress {
	0% {
		stroke-dasharray: 0 100;
	}
}

.circular-check-svg {
	color: $color-black-4;

	&--process {
		stroke: $color-green-5;
		fill: none;
		stroke-linecap: round;
		animation: progress 1s ease-out forwards;
	}
}
