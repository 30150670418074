// ----------------------------------------------------------------------

// Media Query Breakpoints

// ----------------------------------------------------------------------

// example @include breakpoint(940px) { width:80%; }

@mixin breakpoint($size) {
	@media only screen and (max-width: $size) {
		@content;
	}
}

@mixin breakpoint-height($size) {
	@media only screen and (max-height: $size) {
		@content;
	}
}

// ----------------------------------------------------------------------

// Media Query Breakpoints

// ----------------------------------------------------------------------

// example @include min-breakpoint(940px) { width:80%; }

@mixin min-breakpoint($size) {
	@media only screen and (min-width: $size) {
		@content;
	}
}

// ----------------------------------------------------------------------

// Media Query tablet

// ----------------------------------------------------------------------

// example @include minmax-breakpoint(768px, 992px) { width:80%; }

@mixin minmax-breakpoint($size-min, $size-max) {
	@media (min-width: #{$size-min}) and (max-width: #{$size-max - 1px}) {
		@content;
	}
}
