// TODO: Need to refactor
dx-calendar.cleeksy-calendar {
	&.dx-calendar {
		@include rem(min-height, 296);
		@include rem(min-width, 288);
		@include rem(width, 288);
	}

	.dx-widget.dx-calendar-navigator {
		@include vertical-align-flex();
		@include rem(padding-right, 57);

		.dx-calendar-caption-button {
			order: -1;
		}

		.dx-button {
			position: static;
			border: none;
			@include rem(font-size, 14);
			@include rem(line-height, 20);
			font-weight: 600;
			text-transform: lowercase;
			text-decoration: none;
			@include borderRadius(6px);
		}

		.dx-calendar-navigator-next-view,
		.dx-calendar-navigator-previous-view {
			@include rem(height, 32);
			@include rem(width, 32);
			@include rem(margin, 0, 0, 0, 12);

			&.dx-button {
				@include borderRadius(6px);

				.dx-icon {
					color: $color-black-7;
					@include rem(font-size, 14);
				}
			}
		}

		.dx-calendar-navigator-previous-view {
			@include rem(margin-left, auto);
		}

		.dx-calendar-caption-button.dx-button .dx-button-content {
			color: $color-black-10;
			@include rem(padding, 8);
		}

		.dx-button-has-text .dx-button-content {
			.dx-button-text:first-letter {
				text-transform: capitalize;
			}
		}
	}

	&.dx-calendar-view-month {
		.dx-calendar-body {
			@include rem(margin-left, -4);

			thead tr th {
				text-align: center;
				box-shadow: none;
				color: $color-black-5;
				@include rem(font-size, 12);
				@include rem(line-height, 16);
				font-weight: 500;
			}
		}

		.dx-calendar-cell {
			@include rem(font-size, 14);
			@include rem(line-height, 20);
			font-weight: 500;
			@include rem(padding, 0);

			&.dx-calendar-empty-cell {
				pointer-events: none;

				> span {
					color: inherit;
					background: inherit;
				}
			}

			&.dx-calendar-today {
				box-shadow: none !important;

				> span {
					border: unset !important;
					background-color: $color-blue-1;
					color: $color-blue-9;
					font-weight: 500;
				}
			}

			> span {
				@include inline-flex();
				@include align-content(center);
				@include align-items(center);

				@include rem(height, 32);
				@include rem(width, 32);
				@include rem(line-height, 32);
				border-radius: 50%;
				@include rem(margin, 0, 4);

				&.hovering {
					color: $color-blue-9;
					background-color: $color-blue-1;
				}

				&.in-range {
					color: white;
					background: $color-blue-7;
				}
			}

			&.dx-state-hover,
			&.dx-state-active {
				background: #fff;
				box-shadow: none !important;

				> span {
					color: white;
					background: $color-blue-7;
				}
			}

			&.dx-calendar-contoured-date {
				box-shadow: none;
			}

			&.dx-calendar-selected-date {
				box-shadow: none;

				> span {
					color: white;
					background: $color-blue-7;
				}
			}

			> span.start-date,
			> span.end-date {
				color: #fff !important;
				background: $color-blue-7 !important;
			}
		}
	}

	&.dx-calendar-view-decade,
	&.dx-calendar-view-year,
	&.dx-calendar-view-century {
		.dx-calendar-cell {
			@include rem(font-size, 14);
			@include rem(line-height, 20);
			font-weight: 600;
			@include rem(padding, 0);
			box-shadow: none;

			&.dx-calendar-today {
				box-shadow: none !important;
			}

			> span {
				@include inline-flex();
				@include align-items(center);
				@include justify-content(center);

				@include rem(min-height, 36);
				@include rem(line-height, 36);
				@include borderRadius(6px);
				word-spacing: inherit;
			}

			&.dx-state-hover,
			&.dx-state-active {
				background: white;
				box-shadow: none !important;

				> span {
					color: $color-blue-7;
					background: $color-blue-1;
				}
			}

			&.dx-calendar-selected-date {
				box-shadow: none;

				> span {
					color: $color-blue-7;
					background: $color-blue-1;
				}
			}
		}
	}
}
