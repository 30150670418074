//import font

// reset
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none !important;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

html,
body {
	height: 100%;
}

q {
	&:before,
	&:after {
		content: '';
		content: none;
	}
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

button {
	outline: none !important;
	@include transition(all 0.3s ease);

	&:hover {
		@include transition(all 0.3s ease);
	}
}

* {
	&,
	&:before,
	&:after {
		@include box-sizing(border-box);
	}
}

a {
	text-decoration: none;
	@include transition(all 0.3s ease);
	font-weight: 400;

	&:hover {
		text-decoration: none;
		font-weight: 400;
	}
}

a:not([href]):not([class]) {
	color: inherit;
	text-decoration: none;
}

a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none;
}

a > code {
	color: inherit;
}

select:-moz-focusring {
	color: transparent;
	outline: 0;
	text-shadow: 0 0 0 #000;
}

select::-moz-focus-inner {
	border: 0;
	outline: 0;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

pre {
	display: block;
	font-size: 87.5%;
	color: #212529;
}

pre code {
	font-size: inherit;
	color: inherit;
	word-break: normal;
}

input[type='text'],
textarea {
	-webkit-appearance: none;
}

input[type='file'] {
	cursor: pointer;
}

::-webkit-file-upload-button {
	cursor: pointer;
}

input[type='radio'] {
	cursor: pointer;
	outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

[hidden] {
	display: none !important;
}

// NOTE: This fix image alt when failed to load src
img {
	color: rgba(0, 0, 0, 0);
}

img {
	vertical-align: middle;
	border-style: none;
}

svg {
	overflow: hidden;
	vertical-align: middle;
}

::placeholder {
	color: $color-black-5;
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: $color-black-5;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: $color-black-5;
}
