@import '../base/mixins';

html,
body,
div.main-layout__content,
.cdk-virtual-scroll-content-wrapper {
	::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	::-webkit-scrollbar-thumb {
		background: #88888855;

		border-radius: 5px;
		background-clip: content-box;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}
