@import '../variables';
@import '../base/mixins';

dx-button.dx-button,
.dx-dialog-button {
	@include rem(padding, 0, 12);
	@include rem(border-radius, 6);
	@include rem(height, 36);
	font-weight: 500;
	white-space: nowrap;
	@include rem(font-size, 14);
	@include rem(line-height, 20);

	// <dx-button class="button-small></button>
	&.button-small {
		@include rem(height, 32);
		@include rem(font-size, 12);
	}

	// <dx-button class="button-large></button>
	&.button-large {
		@include rem(height, 44);
	}

	&.icon-in-last {
		.dx-button-content {
			direction: rtl;

			> i {
				margin-right: 0;
				margin-left: 9px;
			}
		}
	}

	&.dx-state-disabled {
		background: $color-black-0-5 !important;
		color: $color-black-5 !important;
		border-color: $color-black-2 !important;
		opacity: 1 !important;

		i.dx-icon,
		app-svg-icon {
			color: $color-black-5 !important;
		}
	}

	.dx-button-content {
		padding: 0;
		display: inline-flex;
		align-items: center;
		line-height: inherit;

		.dx-button-text {
			line-height: inherit;
		}
	}

	// Button type normal
	&.dx-button-normal {
		/* Example: <dx-button
                text="Contained"
                stylingMode="contained || outlined"
                type="normal">
            </dx-button>
*/
		&.dx-button-mode-contained,
		&.dx-button-mode-outlined {
			background: #fff;
			border: 1px solid $color-black-3;
			color: $color-black-7;
			// Icon style
			i.dx-icon,
			app-svg-icon {
				color: $color-black-7;
			}

			// Hover, focused, clicked style
			&.dx-state-hover,
			&.dx-state-focused {
				background-color: $color-black-1;

				// Active/click and hold style
				&.dx-state-active {
					background-color: $color-black-2;
				}
			}
		}

		/* Example: <dx-button
                stylingMode="text"
                text="Contained"
                type="normal">
            </dx-button>
*/

		&.dx-button-mode-text {
			background-color: transparent;
			border-color: transparent;
			color: $color-black-7;

			// Icon style
			i.dx-icon,
			app-svg-icon {
				color: $color-black-7;
			}

			// Hover, focused, clicked style
			&.dx-state-hover,
			&.dx-state-focused {
				background: $color-black-1;

				// Active/click and hold style
				&.dx-state-active {
					background-color: $color-black-2;
				}
			}

			&.dx-state-disabled {
				background-color: transparent !important;
				border-color: transparent !important;
			}
		}
	}

	// Button type default
	&.dx-button-default {
		/* Example: <dx-button
                text="Contained"
                type="default">
            </dx-button>
*/
		&.dx-button-mode-contained {
			background: $color-blue-7;
			border: 1px solid $color-blue-7;
			color: white;
			// Icon style
			i.dx-icon,
			app-svg-icon {
				color: white;
			}

			// Hover, focused, clicked style
			&.dx-state-hover,
			&.dx-state-focused {
				background: $color-blue-6;
				border: 1px solid $color-blue-6;

				// Active/click and hold style
				&.dx-state-active {
					background-color: $color-blue-8;
					color: white;
				}
			}
		}

		/* Example: <dx-button stylingMode="outlined"
                text="Outlined"
                 type="default">
            </dx-button>
*/
		&.dx-button-mode-outlined {
			background-color: $color-blue-1;
			border-color: $color-blue-1;
			color: $color-blue-7;

			// Icon style
			i.dx-icon,
			app-svg-icon {
				color: $color-blue-7;
			}

			// Hover, focused, clicked style
			&.dx-state-hover,
			&.dx-state-focused {
				background: $color-blue-2;

				// Active/click and hold style
				&.dx-state-active {
					background-color: $color-blue-3;
				}
			}
		}

		/* Example: <dx-button
                stylingMode="text"
                text="Contained"
                type="default">
            </dx-button>
*/
		&.dx-button-mode-text {
			background-color: transparent;
			border-color: transparent;
			color: $color-blue-7;

			// Icon style
			i.dx-icon,
			app-svg-icon {
				color: $color-blue-7;
			}

			// Hover, focused, clicked style
			&.dx-state-hover,
			&.dx-state-focused {
				background: $color-blue-1;

				// Active/click and hold style
				&.dx-state-active {
					background-color: $color-blue-2;
				}
			}
		}
	}

	// Button type success
	&.dx-button-success {
		/* Example: <dx-button
                text="contained"
                 type="success">
            </dx-button>
*/
		&.dx-button-mode-contained {
			background: $color-green-5;
			border: 1px solid $color-green-5;
			color: #fff;
			// Icon style
			i.dx-icon,
			app-svg-icon {
				color: #fff;
			}

			// Hover, focused, clicked style
			&.dx-state-hover,
			&.dx-state-focused {
				background: $color-green-6;
				border: 1px solid $color-green-6;

				// Active/click and hold style
				&.dx-state-active {
					background-color: $color-green-7;
					border: 1px solid $color-green-7;
				}
			}
		}

		/* Example: <dx-button stylingMode="outlined"
                text="Outlined"
                 type="success">
            </dx-button>
*/
		&.dx-button-mode-outlined {
			background-color: $color-green-1;
			border-color: $color-green-7;
			color: $color-green-7;

			// Icon style
			i.dx-icon,
			app-svg-icon {
				color: $color-green-7;
			}

			// Hover, focused, clicked style
			&.dx-state-hover,
			&.dx-state-focused {
				background-color: $color-green-2;

				// Active/click and hold style
				&.dx-state-active {
					background-color: $color-green-3;
				}
			}
		}
	}

	// Button type danger
	&.dx-button-danger {
		/* Example: <dx-button
                text="contained"
                 type="danger">
            </dx-button>
*/
		&.dx-button-mode-contained {
			background: $color-red-5;
			border: 1px solid $color-red-5;
			color: #fff;
			// Icon style
			i.dx-icon,
			app-svg-icon {
				color: $color-black-4;
			}

			// Hover, focused, clicked style
			&.dx-state-hover,
			&.dx-state-focused {
				background: $color-red-6;
				border: 1px solid $color-red-6;

				// Active/click and hold style
				&.dx-state-active {
					background-color: $color-red-7;
					border: 1px solid $color-red-7;
				}
			}
		}

		/* Example: <dx-button stylingMode="outlined"
                text="Outlined"
                 type="danger">
            </dx-button>
*/
		&.dx-button-mode-outlined {
			background-color: $color-red-1;
			border-color: $color-red-3;
			color: $color-red-5;

			// Icon style
			i.dx-icon,
			app-svg-icon {
				color: $color-red-5;
			}

			// Hover, focused, clicked style
			&.dx-state-hover,
			&.dx-state-focused {
				background: $color-red-2;

				// Active/click and hold style
				&.dx-state-active {
					background-color: $color-red-3;
				}
			}
		}
	}
}

dx-drop-down-button {
	@include rem(height, 32);

	.dx-buttongroup-item.dx-button {
		@include rem(border-radius, 6 !important);
		font-weight: bold;

		&.dx-state-hover,
		&.dx-state-focused {
			@include rem(padding, 0, 15);
		}

		.dx-button-content {
			padding: 0 !important;
		}

		// There are only one type that is normal type for dropdown button
		&.dx-button-normal {
			background: $color-blue-7 !important;
			border: 1px solid transparent;
			color: white;

			// Icon style
			i.dx-icon {
				color: $color-black-4;
			}

			// stylingMode="text"
			&.dx-button-mode-text {
				background: white !important;
				color: $color-blue-7;
				border: none;

				i.dx-icon {
					color: $color-blue-7;
				}
			}

			// stylingMode="outline"
			&.dx-button-mode-outlined {
				background: white !important;
				color: $color-blue-7;
				border: 1px solid $color-blue-7;

				i.dx-icon {
					color: $color-blue-7;
				}
			}

			// Hover, focused, clicked style
			&.dx-state-hover,
			&.dx-state-focused {
				background: $color-blue-7 !important;
				border: 2px solid $color-blue-5;

				// Active/click and hold style
				&.dx-state-active {
					background-color: $color-blue-8 !important;

					&.dx-button-mode-text,
					&.dx-button-mode-outlined {
						background-color: $color-blue-1 !important;
					}
				}

				// stylingMode="text"
				&.dx-button-mode-text {
					border: none;
					background-color: $color-blue-5 !important;
				}

				// stylingMode="outline"
				&.dx-button-mode-outlined {
					background-color: $color-blue-5 !important;
					color: $color-blue-7;
					border: 2px solid $color-blue-5;
				}
			}
		}
	}
}

// Drop down button popup
.dx-dropdownbutton-popup-wrapper.dx-popup-wrapper > .dx-overlay-content {
	margin: 0 !important;
	border: 0 !important;

	@include rem(border-radius, 6);
	@include rem(padding, 4); // Convert padding 4px to rem
}

/*
 Example: 	<div class="btn-import">
				<app-svg-icon class="btn-import--icon"
					[name]="'icon_attachment'">
				</app-svg-icon>
				<span>text</span>
 			</div>
 */
.btn-import {
	display: inline-block;
	border: 1px dashed $color-black-2;
	@include rem(min-height, 40);
	@include rem(min-width, 40);
	position: relative;
	@include borderRadius(6px);
	cursor: pointer;
	@include rem(padding, 15);
	text-align: center;

	&:hover {
		box-shadow: $box-shadow-m;
		border: 1px dashed $color-blue-7;
		color: $color-blue-7;

		app-svg-icon,
		i {
			color: $color-blue-7;
		}
	}

	app-svg-icon,
	i {
		@include rem(width, 12);
		color: $color-black-4;
	}
}

/*
	Example:
		<div class="btn-more-action">
			<i class="dx-icon-plus"></i>
		</div>
*/
.btn-more-action {
	@include rem(width, 32);
	@include rem(height, 32);
	@include rem(border-radius, 8);
	background-color: $color-blue-7;
	@include center-align-flex();
	cursor: pointer;

	&:hover {
		background-color: $color-blue-6;
	}

	&:active {
		background-color: $color-blue-8;
	}

	&:disabled,
	&.disabled {
		background-color: $color-black-1;
		pointer-events: none;

		i,
		app-svg-icon {
			color: $color-black-5;
		}
	}

	i {
		color: #ffffff;
		@include rem(font-size, 14);
	}

	app-svg-icon {
		color: #ffffff;
		@include rem(width, 14);
	}
}

/**
* Button with div and svg-icon inside
**/
.svg-icon-btn {
	cursor: pointer;
	@include rem(width, 32);
	@include rem(height, 32);
	@include rem(padding, 5, 8);
	@include rem(border-radius, 6);
	display: flex;
	align-items: center;
	justify-items: center;
	background-color: white;
	color: $color-black-7;

	&:hover {
		transition: all 0.3ms ease-in;
		background-color: $color-black-1;
	}

	&:active {
		transition: all 0.3ms ease-in;
		background-color: $color-black-2;
	}

	app-svg-icon {
		@include rem(width, 16);
		@include rem(height, 16);
	}

	&--small {
		@include rem(width, 24);
		@include rem(height, 24);
		@include rem(padding, 4);
		@include rem(border-radius, 4);
	}
}

.svg-icon-btn--red {
	background-color: $color-red-0-5;

	&:hover {
		background-color: $color-red-1;
	}

	&:active {
		background-color: $color-red-2;
	}

	app-svg-icon {
		color: $color-red-6;
	}
}

.svg-icon-btn--blue {
	background-color: $color-blue-7;

	&:hover {
		background-color: $color-blue-6;
	}

	&:active {
		background-color: $color-blue-8;
	}

	app-svg-icon {
		color: white;
	}
}

.svg-icon-cycle-btn {
	cursor: pointer;
	background-color: $color-black-3;
	color: $color-black-7;
	@include rem(width, 16);
	@include rem(height, 16);
	@include rem(padding, 2);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-items: center;

	&:hover {
		transition: all 0.3ms ease-in;
		background-color: $color-black-7;
		color: white;

		> app-svg-icon {
			color: white;
		}
	}

	app-svg-icon {
		@include rem(width, 16);
		@include rem(height, 16);
	}
}

.btn-text {
	cursor: pointer;
	color: $color-blue-6;
	@include rem(font-size, 12);

	&:hover,
	&:focus {
		color: $color-blue-8;
	}

	&:active {
		color: $color-blue-9;
	}
}

/**
* Button curved
* Ex:
* 	<dx-button
	[template]="'items'"
	[type]="'default'"
	[stylingMode]="'contained'"
	class="curved-btn">
	<div *dxTemplate="let item of 'items'">
		<app-svg-icon name="icon_add"></app-svg-icon>
		<span class="ml-2">Thêm to-do</span>
	</div>
	</dx-button>
 */
.curved-btn {
	@include border-radius(36px !important);

	&__small {
		@include border-radius(32px !important);
	}

	&__large {
		@include border-radius(44px !important);
	}
}
